import { types } from "../enum/types";

const initialState = {pending:false,error:{code:0,message:''},data:[]};

export const callOnHoldReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.callOnHoldList:
            return  {...state,pending:false,data:[...action.payload]};
        case types.callOnHoldListError:
            return  {...state, pending:false,error:{...action.payload}};
        case types.callOnHoldListPending:
            return  {...state, pending:true};
        case types.callToOnHold:
            return  {...state,data:[...state.data,action.payload]};
        case types.callRemoveToOnHold:
                const dataOnHold = state.data.filter(x=>x.token !== action.payload);
                return  {...state,data:dataOnHold};
        default:
            return state;
    }
}

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { recoverPassword } from '../../../actions/profileActions';

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const dispatch = useDispatch();
    const {pendingRecoverPassword} = useSelector(state => state.profile)

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(!validateForm()){
            dispatch(recoverPassword(email));
            setEmailError('');
        }
    };


    const validateForm = ()=>{
        let error = false;
        setEmailError('');

        if(validator.isEmpty(email)){
            setEmailError('Ingrese un email');
            error = true;
        }
        else if(!validator.isEmail(email)){
            setEmailError('Ingrese un email válido');
            error = true;
        }

        return error;

    }

    return (
        <div className="flex bg-gray-200 items-center justify-center h-screen">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4" onSubmit={handleSubmit}>
                <h2 className="text-vd-normal text-2xl text-right my-6 font-bold">Recuperar Contraseña</h2>

                <p className="text-vd-light italic text-sm my-6">Ingrese el email de su cuenta y le enviaremos una nueva contraseña</p>

                <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3 mb-6 lg:mb-0">
                <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                    ${emailError!==''?'border-red-300 text-red-700':''}`} 
                        id="email" 
                        type="text" 
                        placeholder="miemail@vdoctor.me"
                        maxLength="50"
                        value={email}
                        onChange={({target})=>setEmail(target.value)}
                />
                {emailError !=='' && <p className="text-red-500 text-xs italic">{emailError}</p>}
                </div>

            </div>

            <div className="flex flex-row justify-end flex-wrap -mx-3 mb-6">
                <div className="w-full lg:w-4/12 px-3 mb-6 lg:mb-0 flex">
                    <button className={`w-full bg-vd-normal hover:bg-vd-dark text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline
                                                ${pendingRecoverPassword?'opacity-50 cursor-not-allowed':''}`} 
                                    type="submit"
                                    disabled={pendingRecoverPassword}>
                            {pendingRecoverPassword?'Aguarde...':'Enviar'}                 
                    </button>
                </div>
            </div>

            </form>
        </div>
    )
}

export default ForgotPassword

import React, { useEffect, useState, useRef } from 'react'
import { useAxios } from "../../../hooks/useAxios";
import { useParams, useHistory } from 'react-router-dom';
import { routes } from '../../../enum/routes'

import validator from 'validator';
import { useDispatch } from 'react-redux';
import { manageError } from '../../../actions/manageErrorActions';

const { sendGet, sendPost } = useAxios();

export const Questions = () => {
    const { tokenpar } = useParams();
    const history = useHistory();
    const mountedRef = useRef(true);
    const dispatch = useDispatch();

    const [symptomsList, setSymptomsList] = useState([]);
    const [symptoms, setSymptoms] = useState('');
    const [symptomsError, setSymptomsError] = useState('');

    const [fever, setFever] = useState('');
    const [feverError, setFeverError] = useState('');

    const [temperature, setTemperature] = useState('');
    const [temperatureError, setTemperatureError] = useState('');

    const [antithermic, setAntithermic] = useState('');
    const [antithermicError, setAntithermicError] = useState('');

    const [comments, setComments] = useState('');

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        const loadConsulta= async ()=>{
            const result = await sendGet(`/public/getconsulta?token=${tokenpar}`, {});
            if (!mountedRef.current) {
                return null;
            }
            if(!result.hasError){
                const {estadofinal} = result.data;
                if(estadofinal){
                    history.push(routes.paciente_llamadas_sala_finalizada.replace(':token',tokenpar));
                }
            }
        }

        loadConsulta();

        return () => {
            mountedRef.current = false;
        }
    }, [])

    useEffect(() => {
        const loadData = async () => {
            const result = await sendGet('/public/getsintomas', {});
            if (!mountedRef.current) {
                return null;
            }

            if (!result.hasError) {
                setSymptomsList([...result.data]);
            }
        }
        loadData();

        return () => {
            mountedRef.current = false;
        }
    }, []);

    const validateForm = () => {
        let error = false;
        setSymptomsError('');
        setFeverError('');
        setTemperatureError('');
        setAntithermicError('');

        if (validator.isEmpty(symptoms)) {
            setSymptomsError('Ingrese un sintoma');
            error = true;
        }

        if (validator.isEmpty(fever)) {
            setFeverError('Marque una opción');
            error = true;
        }

        if (validator.isEmpty(temperature)) {
            setTemperatureError('Marque una opción');
            error = true;
        }

        if (validator.isEmpty(antithermic)) {
            setAntithermicError('Marque una opción');
            error = true;
        }

        return error;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const error = validateForm();
        if (error) {
            return;
        }

        setSubmitting(true);

        const dto = {
            token: tokenpar,
            comentarios: comments,
            idsintoma: symptoms,
            fiebre: fever,
            temperatura: temperature,
            antitermico: antithermic
        };

        const result = await sendPost('/public/guardarcuestionario', dto);
        setSubmitting(false);
        if (!result.hasError) {
            history.push(routes.paciente_llamadas_sala.replace(':token', tokenpar))
        }
        else{
            dispatch(manageError(result));
        }

    }

    return (
        <div className="flex flex-col justify-center items-center">

            <h2 className="text-2xl mb-2">Por favor, conteste las siguientes preguntas para ingresar al consultorio</h2>

            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-full md:w-1/2"
                onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6 w-full">
                    <div className="w-full">
                        <label className={`block text-gray-700 text-sm font-bold mb-2 ${symptomsError !== '' ? 'text-red-700' : ''}`}
                            htmlFor="sintoma">
                            Indique su síntoma principal
                        </label>

                        <div className="relative">
                            <select className={`block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500
                                                    ${symptomsError !== '' ? ' border-red-500 text-red-500' : ''}`
                            }
                                id="symptoms"
                                onChange={({ target }) => setSymptoms(target.value)}
                            >
                                <option key="0" id="0">Seleccione...</option>
                                {
                                    symptomsList.map(({ id, nombre }) =>
                                        <option value={id} key={id} id={id}>{nombre}</option>
                                    )
                                }
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>

                        {symptomsError !== '' && <p className="text-red-500 text-xs italic">{symptomsError}</p>}
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6 w-full">
                    <div className="w-full">
                        <label className={`block text-gray-700 text-sm font-bold mb-2 ${feverError !== '' ? 'text-red-700' : ''}`}
                            htmlFor="fever">
                            ¿Lleva más de 48 hs con fiebre?
                        </label>

                        <label className="flex items-center space-x-4">
                            <input
                                type="radio"
                                value="si"
                                checked={fever === "si" ? "checked" : ""}
                                onChange={() => setFever("si")}
                                className={`appearance-none h-5 w-5 border border-gray-400 rounded-full focus:outline-none 
                                ${fever === "si" ? 'bg-gray-800 border-transparent' : ''}`}
                            />
                            <span className="text-gray-600">Si</span>
                        </label>

                        <label className="flex items-center space-x-4">
                            <input
                                type="radio"
                                value="no"
                                checked={fever === "no" ? "checked" : ""}
                                onChange={() => setFever("no")}
                                className={`appearance-none h-5 w-5 border border-gray-400 rounded-full focus:outline-none 
                                ${fever === "no" ? 'bg-gray-800 border-transparent' : ''}`}
                            />
                            <span className="text-gray-600">No</span>
                        </label>

                        {feverError !== '' && <p className="text-red-500 text-xs italic">{feverError}</p>}
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6 w-full">
                    <div className="w-full">
                        <label className={`block text-gray-700 text-sm font-bold mb-2 ${temperatureError !== '' ? 'text-red-700' : ''}`}
                            htmlFor="temperatura">
                            ¿Tiene más de 38° grados de temperatura?
                        </label>

                        <label className="flex items-center space-x-4">
                            <input
                                type="radio"
                                value="si"
                                checked={temperature === "si" ? "checked" : ""}
                                onChange={() => setTemperature("si")}
                                className={`appearance-none h-5 w-5 border border-gray-400 rounded-full focus:outline-none 
                                ${temperature === "si" ? 'bg-gray-800 border-transparent' : ''}`}
                            />
                            <span className="text-gray-600">Si</span>
                        </label>

                        <label className="flex items-center space-x-4">
                            <input
                                type="radio"
                                value="no"
                                checked={temperature === "no" ? "checked" : ""}
                                onChange={() => setTemperature("no")}
                                className={`appearance-none h-5 w-5 border border-gray-400 rounded-full focus:outline-none 
                                ${temperature === "no" ? 'bg-gray-800 border-transparent' : ''}`}
                            />
                            <span className="text-gray-600">No</span>
                        </label>

                        {temperatureError !== '' && <p className="text-red-500 text-xs italic">{temperatureError}</p>}
                    </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6 w-full">
                    <div className="w-full">
                        <label className={`block text-gray-700 text-sm font-bold mb-2 ${antithermicError !== '' ? 'text-red-700' : ''}`}
                            htmlFor="antitermicos">
                            ¿La temperatura baja con antitérmicos?
                        </label>

                        <label className="flex items-center space-x-4">
                            <input
                                type="radio"
                                value="si"
                                checked={antithermic === "si" ? "checked" : ""}
                                onChange={() => setAntithermic("si")}
                                className={`appearance-none h-5 w-5 border border-gray-400 rounded-full focus:outline-none 
                                ${antithermic === "si" ? 'bg-gray-800 border-transparent' : ''}`}
                            />
                            <span className="text-gray-600">Si</span>
                        </label>

                        <label className="flex items-center space-x-4">
                            <input
                                type="radio"
                                value="no"
                                checked={antithermic === "no" ? "checked" : ""}
                                onChange={() => setAntithermic("no")}
                                className={`appearance-none h-5 w-5 border border-gray-400 rounded-full focus:outline-none 
                                ${antithermic === "no" ? 'bg-gray-800 border-transparent' : ''}`}
                            />
                            <span className="text-gray-600">No</span>
                        </label>

                        {antithermicError !== '' && <p className="text-red-500 text-xs italic">{antithermicError}</p>}
                    </div>
                </div>


                <div className="flex flex-wrap -mx-3 mb-6 w-full">
                    <div className="w-full">
                        <label className={`block text-gray-700 text-sm font-bold mb-2`}
                            htmlFor="comentarios">
                            Comentarios sobre el motivo de consulta (opcional)
                        </label>

                        <textarea className="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            id="comments"
                            rows="5"
                            placeholder=""
                            maxLength="50"
                            value={comments}
                            onChange={({ target }) => setComments(target.value)}
                        />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6 w-full">
                    <p className="text-sm text-gray-600 italic mb-2">* Esta aplicación no debe ser utilizada para consultas de Emergencia tales como pérdida de conocimiento, dificultad para respirar, hemorragias, dolores de pecho o cualquier situación de aparición súbita y síntomas severos.</p>
                    <p className="text-sm text-gray-600 italic">* En caso de emergencia debes dirigirte al centro de urgencias más cercano o llamar al número de emergencias médicas de tu localidad.</p>
                </div>
                <div className="flex flex-wrap w-full -mx-3 mb-6 justify-end">
                    <div>
                        <button className={`w-full bg-vd-normal hover:bg-vd-dark text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline
                                          ${submitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={submitting}>
                            {submitting ? 'Aguarde...' : 'Ir a la consulta'}
                        </button>
                    </div>
                </div>

            </form>
        </div>
    );
}
export default Questions;
import React , { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hideMessage } from '../../../actions/messageActions';
import messageSeverity from './../../../enum/messageSeverity'
import DangerIcon from './../../shared/icons/DangerIcon';
import WarningIcon from './../../shared/icons/WarningIcon';
import SuccessIcon from './../../shared/icons/SuccessIcon';
import InfoIcon from './../../shared/icons/InfoIcon';

export const Message = () => {

    const dispatch = useDispatch();

    const {severity,message,open}  = useSelector( state => state.message );

    const handleClose = (event, reason) => {
        dispatch(hideMessage());
    };

    useEffect(() => {
        if(open){
            setTimeout(() => {
                dispatch(hideMessage());
            }, 6000);
        }
    }, [open]);



    return (
        <>
        {open && 
        <div className="fixed top-0 right-0 my-1" onClick={handleClose}>
            {severity === messageSeverity.info &&
                <div className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">
                    <InfoIcon className="fill-current w-6 h-6 mr-2" />
                    <p>{message}</p>
                </div>
            }
            {severity === messageSeverity.warning &&
                <div className="flex items-center bg-orange-500 text-white text-sm font-bold px-4 py-3" role="alert">
                    <WarningIcon className="fill-current w-6 h-6 mr-2"/>
                    <p>{message}</p>
                </div>
            }
            {severity === messageSeverity.error &&
                <div className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3" role="alert">
                    <DangerIcon className="fill-current w-6 h-6 mr-2"/>
                    <p>{message}</p>
                </div>
            }
            {severity === messageSeverity.success &&
                <div className="flex items-center bg-green-500 text-white text-sm font-bold px-4 py-3" role="alert">
                    <SuccessIcon className="fill-current w-6 h-6 mr-2"/>
                    <p>{message}</p>
                </div>
            }

            
            
        </div>
        }
        </>
    )
}

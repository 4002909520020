export const dateFormat=(paramDate)=>{
    const date = new Date(paramDate);
    return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
}
export const timeFormat=(paramDate)=>{
    const date = new Date(paramDate);
    return `${date.getHours()}:${(date.getMinutes()<10?'0':'') + date.getMinutes()} hs`;
}

export const dateTimeFormat = (paramDate)=>{

    const date = new Date(paramDate);

    return `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()} ${date.getHours()}:${(date.getMinutes()<10?'0':'') + date.getMinutes()} hs`;
}


export const getHumanWaitTime = (paramDate)=>{

    const date = new Date(paramDate);

    const time = new Date().getTime() - date.getTime();

    const timeInMinutes =  Math.round(time/60/1000);
    

    if(timeInMinutes<1){
        return 'hace unos instantes'
    }
    else if (timeInMinutes >=1 && timeInMinutes<2){
        return `hace ${timeInMinutes} minuto`
    }
    else if (timeInMinutes >=2 && timeInMinutes<30){
        return `hace ${timeInMinutes} minutos`
    }
    else if(timeInMinutes >=30 && timeInMinutes<45){
        return 'más de media hora'
    }
    else if(timeInMinutes >=45 && timeInMinutes<60){
        return 'más de 45 minutos'
    }
    else if (timeInMinutes >=60){
        const hours = Math.round(timeInMinutes/60);

        if(hours>=1 && hours<2){
            return `hace ${hours} hora`
        }
        else if(hours>=2 && hours <24){
            return `hace ${hours} horas`
        }
        else{
            return "hace más de 1 día";
        }
    }        
    return '----';
};

import React from 'react'
import NavBar from './../../shared/navbar/NavBar';

const LayoutPacient = ({children}) => {
    return (
      <div className=" flex flex-col flex-1 h-screen">
        <NavBar/>
        <div className="flex flex-1 p-2 bg-gray-200 justify-center">
          {children}
        </div>
      </div>
    )
}

export default LayoutPacient

import React, { useEffect, useState } from 'react'
import { TableCustom } from './../../../components/shared/tablecustom/TableCustom'
import { useSelector, useDispatch } from 'react-redux';
import { callHistory, callHistoryNext } from '../../../actions/callHistoryActions';
import { dateTimeFormat } from '../../../helpers/DateFormat';
import filterData from '../../../selectors/filterData';

const CallHistory = () => {

    const [search, setSearch] = useState('');
    const { data, pending } = useSelector(state => state.callHistory);
    const { consultas, pagina } = data;
    const columns = ['token', 'fecha', 'paciente', 'medico', 'ref venta', 'estado', 'duración'];

    const filterCols = ['token', 'fechacreada', 'paciente', 'medico', 'refventa', 'estado'];
    let filterHistories = consultas.map(x => ({ ...x, fechacreada: dateTimeFormat(x.fechacreada) }));
    filterHistories = filterData(search, filterCols, filterHistories);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(callHistory());
    }, [])

    const handleMore = () => {
        dispatch(callHistoryNext(pagina + 1));
    }



    return (
        <>
            <div className="flex justify-end">
                <input className="appearance-none border border-gray-300 rounded w-1/2 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Buscar"
                    maxLength="50"
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                />
            </div>

            <TableCustom rowsCount={filterHistories.length}
                pending={pending}
                columns={columns}
                renderBody={() => (
                    <>
                        {filterHistories.map((row) => (
                            <tr key={`hist_${row.token}`}>
                                <td className="text-center border px-4 py-2 bg-white">{row.token}</td>
                                <td className="text-center border px-4 py-2 bg-white">{row.fechacreada}</td>
                                <td className="text-center border px-4 py-2 bg-white">{row.paciente}</td>
                                <td className="text-center border px-4 py-2 bg-white">{row.medico}</td>
                                <td className="text-center border px-4 py-2 bg-white">{row.refventa}</td>
                                <td className="text-center border px-4 py-2 bg-white">{row.estado}</td>
                                <td className="text-center border px-4 py-2 bg-white">{row.duracion}</td>
                            </tr>
                        ))}
                    </>
                )}

            />
            {
                filterHistories.length !== 0 &&
                <div className="flex justify-center">
                    <button
                        className="text-vd-normal p-2 tracking-normal block text-left focus:outline-none"
                        onClick={handleMore}
                    >
                        {pending ? 'Aguarde...' : 'Ver mas...'}
                    </button>
                </div>
            }
        </>
    )
}

export default CallHistory

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dateFormat } from '../../../helpers/DateFormat';
import filterData from '../../../selectors/filterData';
import Modal from '../../shared/modal/Modal';
import { TableCustom } from '../../shared/tablecustom/TableCustom';
import {userToProcessApproveAction, userToProcessDiscardAction, userToProcessGetListAction} from './../../../actions/userToProcessActions'

const UsersAdmin = () => {

    const dispatch = useDispatch();
    
    const [search, setSearch] = useState('');
    const [userDiscardName, setUserDiscardName] = useState('');
    const [userDiscardId, setUserDiscardId] = useState(0);
    const [showModal, setShowModal] = useState(false);

    const {data,pendingList,pendingApprove, pendingDiscard} = useSelector(state => state.usertoprocess);
    const columns = ['fecha','nombre','email','teléfono'];
    const filterCols = ['fecha','nombrecompleto','email','tel'];
    let filterUsers = data.map(x => ({ ...x, fecha: dateFormat(x.fecha) }));
    filterUsers = filterData(search, filterCols, filterUsers);

    useEffect(() => {

        dispatch(userToProcessGetListAction())

    }, [])


    const handleDiscardModal = ({nombrecompleto,id})=>{
        setUserDiscardName(nombrecompleto);
        setUserDiscardId(id);
        setShowModal(true);
    }

    const handleDiscard = ()=>{
        dispatch(userToProcessDiscardAction(userDiscardId));
        setShowModal(false);
        setUserDiscardId(0);
        setUserDiscardName('');
    }

    const handleApprove=({id})=>{
        dispatch(userToProcessApproveAction(id));
    }

    

    return (
        <>

            <div className="flex justify-end">
                <input className="appearance-none border border-gray-300 rounded w-1/2 py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    type="text"
                    placeholder="Buscar"
                    maxLength="50"
                    value={search}
                    onChange={({ target }) => setSearch(target.value)}
                />
            </div>

           <TableCustom
           rowsCount={filterUsers.length}
           pending={pendingList}
           columns={columns}
           renderBody={() => (
            <>
                {filterUsers.map((row) => (
                    <tr key={`data_${row.id}`}>
                        <td className="text-center border px-4 py-2 bg-white">{row.fecha}</td>
                        <td className="text-center border px-4 py-2 bg-white">{row.nombrecompleto}</td>
                        <td className="text-center border px-4 py-2 bg-white">{row.email}</td>
                        <td className="text-center border px-4 py-2 bg-white">{row.tel}</td>
                        <td className="text-center border px-4 py-2 bg-white">
                            <div className="flex flex-row">
                                <button className={`mr-3 hover:bg-gray-200 text-gray-700 py-1 px-2 rounded focus:outline-none focus:shadow-outline
                                    ${pendingDiscard?'opacity-50 cursor-not-allowed':''}`} 
                                    type="button"
                                    disabled={pendingDiscard}
                                    onClick={()=>handleDiscardModal(row)}>
                                    {pendingDiscard?'Aguarde...':'Descartar'} 
                                </button>
                                <button className={`bg-vd-normal hover:bg-vd-dark text-gray-200 py-1 px-2 rounded focus:outline-none focus:shadow-outline
                                    ${pendingApprove?'opacity-50 cursor-not-allowed':''}`} 
                                    type="button"
                                    disabled={pendingApprove}
                                    onClick={()=>handleApprove(row)}>
                                    {pendingApprove?'Aguarde...':'Aprobar'}                 
                                </button>
                            </div>

                        </td>
                    </tr>
                ))}
            </>
        )}
           >
            </TableCustom> 



            <Modal setShowModal={setShowModal} 
                showModal={showModal} 
                title={`Usuario ${userDiscardName}`}
                body={()=>('¿Está seguro de descartar el usuario?')}
                handleActionOk={handleDiscard}
            />

        </>
    )
}

export default UsersAdmin

import { types } from "../enum/types";

const initialState = {
    severity:'info',
    message:''
};

export const messageReducer = (state=initialState,action) => {
    switch (action.type) {
        case types.uiShowMessage:
            return {...state, ...action.payload};
        case types.uiHideMessage:
                return {...state, open:false};
        default:
            return state;
    }
}

import { types } from '../enum/types';
import {useAxios} from "../hooks/useAxios";
import { manageError } from './manageErrorActions';
import { showMessage } from './messageActions';
import messageSeverity from './../enum/messageSeverity';

const {sendPost} = useAxios();


export const callManualAction=(data)=>{

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(callManualPending());
        const result = await sendPost('/consulta/manual',data,auth_token);

        if(result.hasError){
            dispatch(callManualError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(callManualSuccess(result.data));
        dispatch(showMessage('Se generó la consulta con éxito!',messageSeverity.success));
    }
}





const callManualSuccess =(data)=>({type:types.callManualList,payload:data});
const callManualPending =()=>({type:types.callManualPending});
const callManualError =(code,message)=>({type:types.callManualError,payload:{code,message}});

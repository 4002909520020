import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { getNote, saveNote } from '../../../actions/noteAction';
import validator from 'validator';

const MeetNote = ({token}) => {

    const [note, setNote] = useState('');
    const [noteError, setNoteError] = useState('')
    const {data, pendingSave} = useSelector(state => state.note);
    const {data:dataMeet} = useSelector(state => state.meet);
    const dispatch = useDispatch();


    const validateForm = ()=>{
        let error = false;
        setNoteError('');
        if(validator.isEmpty(note)){
            setNoteError('Ingrese una nota');
            error=true;
        }
        return error;
    }    

    const handleSubmit = (e)=>{
        e.preventDefault();
        const error = validateForm();
        if(!error){
            dispatch(saveNote(token,note));
        }
    }

    useEffect(() => {
        setNote(data);
    }, [data]);

    useEffect(() => {
        if(dataMeet.fechainiciada!=null){
            dispatch(getNote(token));
        }
    }, [dataMeet.fechainiciada]);

    return (
        <form className="bg-white rounded py-8 px-2" onSubmit={handleSubmit}>
            <div className="flex flex-col">
                <label className={`block text-gray-700 font-bold text-lg mb-2 ${noteError!==''?'text-red-700':''}`} 
                    htmlFor="note">
                    notas
                </label>
                <textarea  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                    ${noteError!==''?'border-red-300 text-red-700':''}`} 
                        id="note" 
                        rows="5" 
                        placeholder=""
                        maxLength="50"
                        value={note}
                        onChange={({target})=>setNote(target.value)}
                />
                {noteError !=='' && <p className="text-red-500 text-xs italic">{noteError}</p>}
            </div>

            <div className="flex my-2 justify-end">
                <button className={`bg-vd-normal hover:bg-vd-dark text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline
                                            ${pendingSave?'opacity-50 cursor-not-allowed':''}`} 
                                type="submit"
                                disabled={pendingSave}>
                        {pendingSave?'Aguarde...':'Guardar'}                 
                </button>
            </div>

        </form>



    )
}

export default MeetNote;

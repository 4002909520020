import React from 'react'

const PowerOff = (props) => {
    return (
        <svg {...props}  viewBox="0 0 512 512" fill="currentColor" >
        <g id="XMLID_2_">
            <path id="XMLID_6_" d="M492.7,275.3c0,31.7-6,62.6-18.9,91.8c-12.9,29.2-29.2,54-50.6,75.5c-21.4,21.4-46.3,37.7-75.5,50.6
                S288.6,512,256,512s-62.6-6-91.8-18.9s-54-29.2-75.5-50.6c-21.4-21.4-37.7-46.3-50.6-75.5c-12.9-29.2-18.9-59.2-18.9-91.8
                c0-36.9,8.6-72,24.9-105.5s39.5-60.9,69.5-83.2c8.6-6.9,18.9-9.4,29.2-7.7c11.1,1.7,18.9,6.9,25.7,15.4c6.9,8.6,9.4,18,7.7,29.2
                c-1.7,11.1-6.9,19.7-15.4,25.7c-19.7,15.4-35.2,33.4-46.3,55.7c-11.1,22.3-16.3,45.5-16.3,70.3c0,21.4,4.3,42,12.9,60.9
                c8.6,19.7,19.7,36,33.4,50.6c13.7,13.7,30.9,25.7,50.6,33.4c19.7,7.7,39.5,12.9,60.9,12.9c21.4,0,42-4.3,60.9-12.9
                c18.9-8.6,36-19.7,50.6-33.4c13.7-13.7,25.7-30.9,33.4-50.6c8.6-19.7,12.9-39.5,12.9-60.9c0-24.9-5.1-48-16.3-70.3
                c-11.1-22.3-26.6-40.3-46.3-55.7c-8.6-6.9-13.7-15.4-15.4-25.7c-1.7-11.1,0.9-20.6,7.7-29.2c6-8.6,14.6-13.7,25.7-15.4
                c11.1-1.7,20.6,0.9,29.2,7.7c30,22.3,53.2,49.7,69.5,83.2C484.1,203.3,492.7,237.6,492.7,275.3z M296.3,39.5v196.4
                c0,10.3-4.3,19.7-12,27.4c-7.7,7.7-17.2,12-27.4,12c-10.3,0-19.7-4.3-27.4-12c-7.7-7.7-12-17.2-12-27.4V39.5
                c0-10.3,4.3-19.7,12-27.4c7.7-7.7,17.2-12,27.4-12c10.3,0,19.7,4.3,27.4,12C292,19.7,296.3,29.2,296.3,39.5z"/>
        </g>
        </svg>
    )
}

export default PowerOff

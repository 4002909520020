import { types } from "../enum/types";

const initialState = {
    pendingList: false,
    pendingDiscard:false,
    pendingApprove:false,
    error: {
        code: 0,
        message: ''
    },
    data:[]
};

export const userToProcessReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.userToProcessListPending:
            return { ...state, pendingList: true,  };
        case types.userToProcessListSuccess:
            return { ...state, pendingList: false, data: [...action.payload ] };
        case types.userToProcessListError:
            return { ...state, pendingList: false, error: { ...action.payload } };

        case types.userToProcessDiscardPending:
            return { ...state, pendingDiscard: true,  };
        case types.userToProcessDiscardSuccess:
            return { ...state, pendingDiscard: false, };
        case types.userToProcessDiscardError:
            return { ...state, pendingDiscard: false, error: { ...action.payload } };

        case types.userToProcessApprovePending:
            return { ...state, pendingApprove: true };
        case types.userToProcessApproveSuccess:
            return { ...state, pendingApprove: false };
        case types.userToProcessApproveError:
            return { ...state, pendingApprove: false, error: { ...action.payload } };

        default:
            return state;
    }
}
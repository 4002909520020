import React from 'react'
import ThumbUpIcon from '../icons/ThumbUpIcon'

const SignUpSuccess = () => {
    return (
        <div className="flex flex-col justify-center bg-gray-200 items-center h-screen overflow-auto">
            <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-4 w-3/4 lg:w-1/2 " >
                <div className="flex flex-col justify-center items-center">
                    <ThumbUpIcon className="w-24 h-24 text-vd-normal mt-4 mb-2" />
                    <p className="text-3xl text-vd-normal">¡Te has registrado exitosamente!</p>
                    <p className="text-vd-dark italic mt-10">En breve nos contactaremos para que pueda acceder a la plataforma.</p>
                </div>
            </div>

        </div>
    )
}

export default SignUpSuccess

export const routes ={
    root:'/',
    login:'/login',
    forgot_password:'/olvide-mi-clave',
    signup:'/registarme',
    signup_success:'/registarme/ok',
    admin_root:'/admin',
    admin_inicio:'/admin/inicio',
    admin_perfil:'/admin/perfil',
    admin_users:'/admin/usuarios',
    doctor_root:'/doctor/inicio',
    doctor_inicio:'/doctor/inicio',
    doctor_perfil:'/doctor/perfil',
    doctor_llamadas:'/doctor/llamadas',
    doctor_llamadas_sala:'/doctor/llamadas/sala/:token',
    doctor_llamadas_nueva:'/doctor/llamadas/nueva',
    paciente_root:'/paciente',
    paciente_checkin: '/paciente/consulta/checkin/:tokenpar',
    paciente_checkin_questions: '/paciente/consulta/questions/:tokenpar',
    paciente_llamadas_sala:'/paciente/llamadas/sala/:token',
    paciente_llamadas_sala_finalizada:'/paciente/llamada/finalizada/:token',
}
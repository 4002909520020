import React from 'react'

const Modal = ({setShowModal,showModal,body,title,handleActionOk,titleButtonOk='De Acuerdo',pending=false}) => {

    const handleOk = ()=>{
      if(handleActionOk){
        handleActionOk();
      }
      else{
        setShowModal(false);
      }
    }

    return (
        <>
        {
        showModal && 
        <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-sm">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between px-4 py-2 border-b border-solid border-gray-300 rounded-t">
                  <h3 className="text-vd-dark font-semibold">
                    {title}
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-4 flex-auto">
                  <div className="my-4 text-gray-600 text-lg leading-relaxed">
                    {body()}
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b">
                <button
                    className="text-gray-500 background-transparent font-bold uppercase px-2 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={() => setShowModal(false)}
                  >
                    Cerrar
                </button>
                
                <button
                    className={`background-transparent text-vd-normal font-bold uppercase text-sm px-2 py-3 outline-none focus:outline-none mr-1 mb-1
                                ${pending?'opacity-50 cursor-not-allowed':''}`} 
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={handleOk}
                  >
                    {pending?'Aguarde...':titleButtonOk}   
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        }
        </>
    )
}

export default Modal

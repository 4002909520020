import React from 'react'
import { useDispatch } from 'react-redux';
import { showMessage } from '../../../actions/messageActions';
import messageSeverity from '../../../enum/messageSeverity';
import CopyIcon from '../icons/CopyIcon'

const ShareCopy = ({textToCopy}) => {

    const dispatch = useDispatch();

    const handleCopy = ()=>{
        let aux = document.createElement("input");
        aux.setAttribute("value", textToCopy);
        document.body.appendChild(aux);
        aux.select();
        document.execCommand("copy");
        document.body.removeChild(aux);
    
        dispatch(showMessage('Se copió al portapapeles',messageSeverity.info))
    }
    
    return (
        <>
            <button 
                className="rounded-full bg-gray-500 hover:bg-gray-600 focus:outline-none w-10 h-10 flex items-center justify-center"
                onClick={handleCopy}
            >
                <CopyIcon className="w-6 h-6 text-gray-100"/>
            </button>
            <p className="text-sm text-gray-500">copiar</p>
        </>
    )
}

export default ShareCopy

import { types } from '../enum/types';
import { useAxios } from "../hooks/useAxios";
import { manageError } from './manageErrorActions';
import { showMessage } from './messageActions';
import messageSeverity from './../enum/messageSeverity';

const { sendPost } = useAxios();

export const shareEmailAction = (token, email) => {

    return async (dispatch, getState) => {
        const { auth_token } = getState().auth;

        const data = { 'token': token, 'email': email };

        dispatch(shareEmailPending());
        const result = await sendPost(`/consulta/compartir`, data, auth_token);

        if (result.hasError) {
            dispatch(shareEmailError(result.errorCode, result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(shareEmailSuccess());
        dispatch(showMessage('Se ha compartido con éxito', messageSeverity.success));
    }
}

export const shareWhatsappAction = (token, phone) => {

    return async (dispatch, getState) => {

        const { auth_token } = getState().auth;

        const data = { 'token': token, 'telefono': phone };

        dispatch(shareWhatsappPending());
        const result = await sendPost('/consulta/compartir', data, auth_token);

        if (result.hasError) {
            dispatch(shareWhatsappError(result.errorCode, result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(shareWhatsappSuccess());
    }
}


const shareEmailSuccess = (data) => ({ type: types.share });
const shareEmailPending = () => ({ type: types.sharePending });
const shareEmailError = (code, message) => ({ type: types.shareError, payload: { code, message } });

const shareWhatsappSuccess = (data) => ({ type: types.share });
const shareWhatsappPending = () => ({ type: types.sharePending });
const shareWhatsappError = (code, message) => ({ type: types.shareError, payload: { code, message } });
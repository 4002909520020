import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {changeProfileDataAction} from '../../../actions/profileActions';
import ChangePassword from '../auth/ChangePassword';
import validator from 'validator';
import { homeData } from '../../../actions/homeActions';

const Profile = () => {

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [user, setUser] = useState('');
    const [birthday, setBirthday] = useState('');

    const [nameError, setNameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const {user:dataUser} = useSelector(state => state.user);
    const {pendingChangeProfileData} = useSelector(state => state.profile);

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(homeData());
    }, [])


    useEffect(() => {

        const {name, surname, tel, email, user,birthday} = dataUser;
        setName(name);
        setSurname(surname);
        setEmail(email);
        setPhone(tel);
        setUser(user);
        setBirthday(birthday);
        
    }, [dataUser]);

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(!validateForm()){
            const requestData = {
                user: user,
                name: name,
                surname: surname,
                email: email,
                birthday:birthday,
                tel: phone
            }

            dispatch(changeProfileDataAction(requestData));
            clearError();
        }
    }

    const clearError =()=>{
        setNameError('');
        setSurnameError('');
        setEmailError('');
        setPhoneError('');
    }

    const validateForm = ()=>{
        clearError();
        let error = false;

        if(validator.isEmpty(name)){
            setNameError('Ingrese un nombre');
            error= true;
        }
        if(validator.isEmpty(surname)){
            setSurnameError('Ingrese un apellido');
            error= true;
        }

        if(validator.isEmpty(email)){
            setEmailError('Ingrese un email');
            error= true;
        }
        else if(!validator.isEmail(email)){
            setEmailError('Ingrese un email válido');
            error= true;
        }

        return error;

    }

    return (
        <div className="flex flex-col items-center">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4" onSubmit={handleSubmit}>
                <h2 className="text-vd-normal text-2xl text-right my-6 font-bold">Mi Perfil</h2>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${nameError!==''?'text-red-700':''}`} 
                        htmlFor="name">
                        Nombre
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${nameError!==''?'border-red-300 text-red-700':''}`} 
                            id="name" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={name}
                            onChange={({target})=>setName(target.value)}
                    />
                    {nameError !=='' && <p className="text-red-500 text-xs italic">{nameError}</p>}
                    </div>


                    <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${surnameError!==''?'text-red-700':''}`} 
                        htmlFor="surname">
                        Apellido
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${surnameError!==''?'border-red-300 text-red-700':''}`} 
                            id="surname" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={surname}
                            onChange={({target})=>setSurname(target.value)}
                    />
                    {surnameError !=='' && <p className="text-red-500 text-xs italic">{surnameError}</p>}
                    </div>

                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${emailError!==''?'text-red-700':''}`} 
                        htmlFor="email">
                        e-mail
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${emailError!==''?'border-red-300 text-red-700':''}`} 
                            id="email" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={email}
                            onChange={({target})=>setEmail(target.value)}
                    />
                    {emailError !=='' && <p className="text-red-500 text-xs italic">{emailError}</p>}
                    </div>


                    <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${phoneError!==''?'text-red-700':''}`} 
                        htmlFor="phone">
                        Teléfono
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${phoneError!==''?'border-red-300 text-red-700':''}`} 
                            id="phone" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={phone}
                            onChange={({target})=>setPhone(target.value)}
                    />
                    {phoneError !=='' && <p className="text-red-500 text-xs italic">{phoneError}</p>}
                    </div>

                </div>

                <div className="flex flex-row justify-end flex-wrap -mx-3 mb-6">
                    <div className="w-full lg:w-4/12 px-3 mb-6 lg:mb-0 flex">
                        <button className={`w-full bg-vd-normal hover:bg-vd-dark text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline
                                                    ${pendingChangeProfileData?'opacity-50 cursor-not-allowed':''}`} 
                                        type="submit"
                                        disabled={pendingChangeProfileData}>
                                {pendingChangeProfileData?'Aguarde...':'Guardar'}                 
                        </button>
                    </div>
                </div>
            </form>
            <ChangePassword/>  
        </div>  
    )
}

export default Profile

import { types } from "../enum/types";

const initialState = {pending:false,pendingSave:false,error:{code:0,message:''},data:''};

export const noteReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.getNotePending:
            return  {...state,pending:true};
        case types.getNoteSuccess:
                return  {...state,pending:false,data:action.payload};
        case types.getNoteError:
                return  {...state,pending:false,error:{...action.payload}};
        case types.saveNotePending:
            return  {...state,pendingSave:true};
        case types.saveNoteSuccess:
                return  {...state,pendingSave:false};
        case types.saveNoteError:
                return  {...state,pendingSave:false,error:{...action.payload}};
        default:
            return state;
    }
}
import { types } from "../enum/types";

const initialState = {
    pending:false,
    error:{code:0,message:''},
}

export const loginReducer = (state = initialState,action) => {
    switch (action.type) {
        case types.authLoginSuccess:
            return {...state,pending:false,error:{code:0,message:''}};
        case types.authLoginPending:
                return {...state, pending:true};
        case types.authLoginError:
                return {...state,pending:false, error:{...action.payload}};
        default:
            return state;
    }
}

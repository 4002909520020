import React, { useEffect, useState, useRef } from 'react'
import { useAxios } from "../../../hooks/useAxios";
import { useParams, useHistory } from 'react-router-dom';
import { routes } from '../../../enum/routes'
import { useDispatch } from 'react-redux';
import { manageError } from '../../../actions/manageErrorActions';
import Loading from '../../shared/loading/Loading';
const { sendGet, sendPost } = useAxios();

export const CheckIn = () => {
    const { tokenpar } = useParams();
    const history = useHistory();
    const mountedRef = useRef(true);
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);

    const [patientDetail, setPatientDetail] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadConsulta= async ()=>{
            setLoading(true);
            const result = await sendGet(`/public/getconsulta?token=${tokenpar}`, {});

            if (!mountedRef.current) {
                return null;
            }

            if(!result.hasError){
                const { estadofinal, requierecuestionario, pacientedetalle } = result.data;
                setPatientDetail(pacientedetalle);
                if (estadofinal) {
                    history.push(routes.paciente_llamadas_sala_finalizada.replace(':token', tokenpar));
                } else if (requierecuestionario) {
                    history.push(routes.paciente_checkin_questions.replace(':tokenpar', tokenpar));
                }
            }

            setLoading(false);
        }

        loadConsulta();

        return () => {
            mountedRef.current = false;
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        const result = await sendPost(`/public/ingresar?token=${tokenpar}`);
        setSubmitting(false);
        if (!result.hasError) {
            history.push(routes.paciente_llamadas_sala.replace(':token', tokenpar))
        }
        else{
            dispatch(manageError(result));
        }
    }

    return (
        loading ? 
            <div className="flex flex-col flex-1">  
                <div className="flex flex-1 items-center justify-center">
                    <Loading message="Aguarde, por favor..." />
                </div>
            </div>
        :
        <div className="flex flex-1 flex-col justify-center items-center m-1">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 w-full lg:w-2/5"
                onSubmit={handleSubmit}>
                <div className="flex flex-1 flex-col">
                    <p className="mb-2 text-vd-normal">
                        Hola <b>{patientDetail.nombre}</b>, su consulta está por comenzar. Cuando esté listo oprima 
                        <button className={`ml-2 bg-vd-normal hover:bg-vd-dark text-gray-200 py-1 px-4 rounded focus:outline-none focus:shadow-outline
                                          ${submitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                            type="submit"
                            disabled={submitting}>
                            {submitting ? 'Aguarde...' : 'Ir a la consulta'}
                        </button>
                    </p>
                </div>
            </form>
        </div>
        
    );
}
export default CheckIn;
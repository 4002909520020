import { types } from "../enum/types";

const initialState = {
    auth_token:'',
    isAuthenticated:false,
    user:{user: '', name: '', surname: '', email:'', role:''},
    permisos:{consultaManual:null,esAdmin:null}
}

export const authReducer = (state = initialState,action) => {
    switch (action.type) {
        case types.authSuccess:
            return {...state, ...action.payload};
        case types.authClear:
            return {...state,auth_token:'',isAuthenticated:false,user:{}};
        default:
            return state;
    }
}

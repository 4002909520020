import React from 'react';
import logo from './../../../images/logos/logos-02.png';

const Loading = ({message}) => {
    return (
        <div className="flex flex-col items-center justify-center ">
            <div className="flex flex-col items-center bg-white shadow-md rounded w-64">
                <img className="animate-pulse" src={logo} alt="logo"/>
                <p className="font-bold text-vd-normal text-center pb-3">{message}</p>
            </div>

        </div>
    )
}

export default Loading

import React from 'react'
import SideBar from '../../shared/sidebar/SideBar'
import MenuBottomAdmin from '../menu/MenuBottomAdmin'
import MenuTopAdmin from '../menu/MenuTopAdmin'

const LayoutAdmin = ({children}) => {
    return (
        <div className="h-screen bg-gray-200 flex flex-col lg:flex-row">
          <SideBar menuTop={MenuTopAdmin} menuBottom={MenuBottomAdmin}/>
          <div className="flex-1 flex flex-col  overflow-y-scroll overflow-x-hidden p-2">
            {children}
          </div>
        </div>
    )
}

export default LayoutAdmin

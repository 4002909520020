import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { routes } from '../../../enum/routes';
import HomeIcon from '../../shared/icons/HomeIcon';
import UsersIcon from '../../shared/icons/UsersIcon';


const MenuTopAdmin = () => {

    const [selectedMenu, setSelectedMenu] = useState(routes.admin_inicio);

    return (
        <>
            <Link to={routes.admin_inicio} 
                  className={`flex text-white hover:bg-vd-dark p-2 tracking-widest ${selectedMenu===routes.admin_inicio?'font-bold':''}`}
                  onClick={() => {
                            setSelectedMenu(routes.admin_inicio)
                          }}
            >
              <HomeIcon className="w-6 h-6 text-gray-100 mr-4"/>
              Inicio
            </Link>
            <Link to={routes.admin_users} 
                  className={`flex text-white hover:bg-vd-dark p-2 tracking-widest ${selectedMenu===routes.admin_users?'font-bold':''}`}
                  onClick={() => {
                                  setSelectedMenu(routes.admin_users)
                          }}
              >
              <UsersIcon className="w-6 h-6 text-gray-100 mr-4"/>
              Usuarios Pendientes
              </Link>
        </>
    )
}

export default MenuTopAdmin

import React, { useRef, useState } from 'react'
import { createPopper } from "@popperjs/core";
import CallDetail from './CallDetail';
import DotsVerticalIcon from '../../shared/icons/DotsVerticalIcon';
import BeakerIcon from '../../shared/icons/BeakerIcon';
import CallStatus from './CallStatus';
import CallButtons from './CallButtons';

const CallStackItem = ({detail,onHold}) => {

    const [showDetail, setShowDetail] = useState(false);
    const btnRef = useRef();
    const popoverRef  = useRef();
    const {paciente, sintoma}=detail;

    const openPopover = ()=>{
         createPopper(btnRef.current, popoverRef.current);
          setShowDetail(true);
    }

    const closePopover =()=>{
        setShowDetail(false);
    }

    return (
        <div className="flex flex-col bg-vd-light my-1">
            <div className="flex items-center m-1">
                <div className="flex flex-col flex-1">
                    <p className="text-gray-200 font-bold">{paciente}</p>
                    {sintoma!==null ?
                        <div className="flex">
                            <BeakerIcon className="h-4 w-4 text-green-100 mr-1" />
                            <p className="text-green-100 text-xs italic">{sintoma}</p>
                        </div> 
                        :
                        <div className="flex">
                            <p className="text-green-100 text-xs italic">Consulta Manual</p>
                        </div>
                    }

                    <div className="flex">
                        <CallStatus detail={detail} onHold={onHold}/>
                    </div>
                </div>
                
                <div>
                {sintoma!==null &&
                    <button onClick={() => {showDetail ? closePopover() : openPopover();}}
                        className="focus:outline-none"  
                        ref={btnRef}
                    >
                        <DotsVerticalIcon className="h-6 w-6 text-white"/>
                    </button>
                }
                    
                    <div ref={popoverRef}>
                        {showDetail && <CallDetail detail={detail} closePopover={closePopover}/>}
                    </div>
                </div>

                
            </div>
            <div className="flex items-center  justify-center my-1 w-full">
                <div className="flex justify-around flex-1">
                    <CallButtons onHold={onHold} detail={detail} />
                </div>
            </div>

           
        </div>
    )
}

export default CallStackItem

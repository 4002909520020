import { types } from "../enum/types";
import {useAxios} from "../hooks/useAxios";
import { manageError } from "./manageErrorActions";


const {sendPost} = useAxios();

export const getNote=(token)=>{

    return async (dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(getNotePending());
        const result = await sendPost(`/consulta/obtenernotasmedico?token=${token}`,{},auth_token);

        
        if(result.hasError){
            dispatch(getNoteError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(getNoteSuccess(result.data));
    };
};

const getNoteSuccess=(data)=>({type: types.getNoteSuccess,payload:data});
const getNotePending =()=>({type:types.getNotePending});
const getNoteError =(code,message)=>({type:types.getNoteError,payload:{code,message}});



export const saveNote=(token,text)=>{

    return async (dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(saveNotePending());

        const result = await sendPost(`/consulta/guardarnotasmedico?token=${token}&notas=${text}`,{},auth_token);
        if(result.hasError){
            dispatch(saveNoteError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(saveNoteSuccess());
    };
};

const saveNoteSuccess=()=>({type: types.saveNoteSuccess});
const saveNotePending =()=>({type:types.saveNotePending});
const saveNoteError =(code,message)=>({type:types.saveNoteError,payload:{code,message}});
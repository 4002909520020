import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { routes } from '../../../enum/routes';
import UserIcon from '../../shared/icons/HomeIcon';

const MenuBottomDoctor = () => {
    const [selectedMenu, setSelectedMenu] = useState(routes.doctor_inicio);
    
    return (
        <>
            <Link to={routes.doctor_perfil} 
                    className={`flex text-white hover:bg-vd-dark p-2 tracking-widest ${selectedMenu===routes.doctor_perfil?'font-bold':''}`}
                    onClick={() => {
                                setSelectedMenu(routes.doctor_perfil)
                            }}
            >
                <UserIcon className="w-6 h-6 text-gray-100 mr-4"/>
                Mi Perfil
            </Link>
        </>
    )
}

export default MenuBottomDoctor

import { types } from "../enum/types";

const initialState = {data:{},hasError:false,errorCode:0,errorMessage:''};

export const manageErrorReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.addError:
            return {...state, ...action.payload};
        default:
            return state;
    }
}
import React from 'react'
import { getHumanWaitTime } from '../../../helpers/DateFormat'
import ClockIcon from '../../shared/icons/ClockIcon'
import PhoneIcon from '../../shared/icons/PhoneIcon';

const CallStatus = ({detail,onHold}) => {
    const {fechacreada}=detail;
    return (
            onHold?
            <>
                <ClockIcon className="h-4 w-4 text-gray-200 mr-1" />
                <p className="text-gray-200 text-xs italic">{getHumanWaitTime(fechacreada)}</p>
            </>:
            <>
                <PhoneIcon className="h-4 w-4 text-green-100 mr-1" />
                <p className="text-green-100 text-xs italic">En curso</p>
            </>           
    )
}

export default CallStatus

import { types } from '../enum/types';
import { useAxios } from "../hooks/useAxios";
import { manageError } from './manageErrorActions';
import { showMessage } from './messageActions';
import messageSeverity from '../enum/messageSeverity';

const { sendGet, sendPost } = useAxios();

export const callInProgressList = () => {

    return async (dispatch, getState) => {

        const { auth_token } = getState().auth;

        dispatch(callInProgressListPending());
        const result = await sendGet('/consulta/encurso', {}, auth_token);

        if (result.hasError) {
            dispatch(callInProgressListError(result.errorCode, result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(callInProgressListSuccess(result.data));
    };
};

const callInProgressListSuccess = (data) => ({ type: types.callInProgressList, payload: data });
const callInProgressListPending = () => ({ type: types.callInProgressListPending });
const callInProgressListError = (code, message) => ({ type: types.callInProgressListError, payload: { code, message } });

export const callOnHoldList = () => {
    return async (dispatch, getState) => {

        const { auth_token } = getState().auth;

        dispatch(callOnHoldListPending());
        const result = await sendGet('/consulta/enespera', {}, auth_token);

        if (result.hasError) {
            dispatch(callOnHoldListError(result.errorCode, result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(callOnHoldListSuccess(result.data));
    };
};


export const callBackToOnHold = (token) => {

    return async (dispatch, getState) => {

        const { auth_token } = getState().auth;

        const result = await sendPost(`/consulta/volveraespera?token=${token}`, {}, auth_token);

        if (result.hasError) {
            dispatch(manageError(result));
            return;
        }

        dispatch(callToOnHold(result.data));
        dispatch(callRemoveFromInProgress(token));
        dispatch(showMessage('La consulta se pasó a EN ESPERA', messageSeverity.info));
    };
};

export const callOnHoldFinalize = (token) => {
    return async (dispatch, getState) => {

        const { auth_token } = getState().auth;

        const result = finalize(auth_token, token);

        if (result.hasError) {
            dispatch(manageError(result));
            return;
        }

        dispatch(callRemoveToOnHold(token));
        dispatch(showMessage('Se finalizó la Llamada', messageSeverity.info));
    }
}

export const callOnHoldCancel = (token) => {
    return async (dispatch, getState) => {

        const { auth_token } = getState().auth;

        const result = sendPost(`/consulta/cancelar?token=${token}`, {}, auth_token);

        if (result.hasError) {
            dispatch(manageError(result));
            return;
        }

        dispatch(callRemoveToOnHold(token));
        dispatch(showMessage('Se canceló la Llamada', messageSeverity.info));
    }
}

export const callInProgressFinalize = (token) => {
    return async (dispatch, getState) => {

        const { auth_token } = getState().auth;

        const result = finalize(auth_token, token);

        if (result.hasError) {
            dispatch(manageError(result));
            return;
        }

        dispatch(callRemoveFromInProgress(token));
        dispatch(showMessage('Se finalizó la Llamada', messageSeverity.info));
    }
}


const finalize = async (auth_token, token) => {
    return await sendPost(`/consulta/finalizar?token=${token}`, {}, auth_token);
}

export const callToOnHold = (data) => ({ type: types.callToOnHold, payload: data });
export const callRemoveFromInProgress = (token) => ({ type: types.callRemoveToInProgress, payload: token });
export const callRemoveToOnHold = (token) => ({ type: types.callRemoveToOnHold, payload: token });

const callOnHoldListSuccess = (data) => ({ type: types.callOnHoldList, payload: data });
const callOnHoldListPending = () => ({ type: types.callOnHoldListPending });
const callOnHoldListError = (code, message) => ({ type: types.callOnHoldListError, payload: { code, message } });


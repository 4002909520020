import { types } from "../enum/types";

const initialState = {
    pending: false,
    error: { code: 0, message: '' },
    data: {
        token: '',
        paciente: '',
        pacientedetalle: {
                    id:0,
                    nombre:null,
                    tel:null,
                    fechanacimiento:null,
                    email:null,
                    edad:0,
        },
        medico: '',
        sala: {
            url: '',
            dominio: '',
            puerto: null,
            id: ''
        },
        fechainiciada: null,
        fechacreada: null,
        fechafinalizada:null
    }
}

export const meetReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.createMeetSuccess:
            return { ...state, pending: false, error: { code: 0, message: '' }, data: { ...action.payload } }
        case types.createMeetError:
            return { ...state, pending: false, error: { ...action.payload } }
        case types.createMeetPending:
            return { ...state, pending: true }
        case types.initMeetSuccess:
            return { ...state, pending: false, error: { code: 0, message: '' }, data: { ...action.payload } }
        case types.initMeetError:
            return { ...state, pending: false, error: { ...action.payload } }
        case types.initMeetPending:
            return { ...state, pending: true }
        case types.endMeet:
            return { ...state, data: { ...state.data , fechafinalizada: action.payload } }
        case types.getMeetSuccess:
            return { ...state, pending: false, error: { code: 0, message: '' }, data: { ...action.payload } }
        case types.getMeetError:
            return { ...state, pending: false, error: { ...action.payload } }
        case types.getMeetPending:
            return { ...state, pending: true }
        default:
            return state;
    }
}

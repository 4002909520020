import React from 'react'

const UserIcon = (props) => {
    return (
        <svg {...props}  stroke="currentColor" fill="currentColor"  viewBox="0 0 24 24" ><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" /></svg>
        
    )
}

export default UserIcon

import axios from 'axios';

const configAxios= (authToken)=>{
    axios.defaults.headers.common['Authorization'] =authToken;
}

const saveError= (error, response) =>{
    if (error.response) {
        response.errorCode = error.response.status;
        response.hasError = true;
        response.errorMessage = error.response.statusText;
        response.data = error.response.data;
    } else if (error.request) {
        response.errorCode = error.request.status;
        response.hasError = true;
        response.errorMessage = error.request.statusText;
        response.data = error.request.data;
    } else {
        response.errorCode = error.code;
        response.hasError = true;
        response.errorMessage = error.message;
        response.data = error.data;
    }
}

export const useAxios = () => {

    const sendGet = async(url,data,authToken='')=>{


        let response = { data: null, hasError: false, errorCode: 0, errorMessage: '' };
        configAxios(authToken);
      
        try {
            const result =  await axios.get(url, data);
            response.data = result.data;

        } catch(error){
            saveError(error,response);
            console.log(error);
        }
        
        return response;
    };

    const sendPost = async (url,data,authToken='')=>{

        let response = { data: null, hasError: false, errorCode: 0, errorMessage: '' };
        configAxios(authToken);
        try {
            const result =  await axios.post(url, data);
            response.data = result.data;

        } catch(error){
            console.log(error);
            saveError(error,response);
        }
        
        return response;
    };


    const sendPut = async (url,data,authToken='')=>{

        let response = { data: null, hasError: false, errorCode: 0, errorMessage: '' };
        configAxios(authToken);
        try {
            const result =  await axios.put(url, data);
            response.data = result.data;

        } catch(error){
            saveError(error,response);
        }
        
        return response;
    };

    return {sendGet,sendPost,sendPut};

}

import { types } from "../enum/types";

const initialState = {  pendingChangePassword:false,
                        pendingChangeProfileData:false,
                        pendingRecoverPassword:false,
                        error:{code:0,message:''},
                        data:{consultorioid:'',user:'',name:'',surname:'',email:'',role:'',birthday:'',tel:''}
                    };

export const profileReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.changePasswordPending:
            return  {...state,pendingChangePassword:true,submit:false};
        case types.changePasswordSuccess:
            return  {...state,pendingChangePassword:false};
        case types.changePasswordError:
            return  {...state,pendingChangePassword:false,error:{...action.payload}};

        case types.changeProfileDataPending:
            return  {...state,pendingChangeProfileData:true,submit:false};
        case types.changeProfileDataSuccess:
            return  {...state,pendingChangeProfileData:false};
        case types.changeProfileDataError:
            return  {...state,pendingChangeProfileData:false,error:{...action.payload}};

        case types.recoverPasswordPending:
            return  {...state,pendingRecoverPassword:true,submit:false};
        case types.recoverPasswordSuccess:
            return  {...state,pendingRecoverPassword:false};
        case types.recoverPasswordError:
            return  {...state,pendingRecoverPassword:false,error:{...action.payload}};

        default:
            return state;
    }
}

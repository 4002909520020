import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { loginReducer } from '../reducers/loginReducer';
import { messageReducer } from '../reducers/messageReducer';
import { manageErrorReducer } from '../reducers/manageErrorReducer';
import { meetReducer } from '../reducers/meetReducer';
import { authReducer } from '../reducers/authReducer';
import { callInProgressReducer } from '../reducers/callInProgressReducer';
import { callOnHoldReducer } from '../reducers/callOnHoldReducer';
import { callHistoryReducer } from '../reducers/callHistoryReducer';
import { userReducer } from '../reducers/userReducer';
import { profileReducer } from '../reducers/profileReducer';
import { noteReducer } from '../reducers/noteReducer';
import { questionReducer } from '../reducers/questionReducer';
import { callManualReducer } from '../reducers/callManualReducer';
import { shareReducer } from '../reducers/shareReducer';
import { signupReducer } from '../reducers/signupReducer';
import { userToProcessReducer } from '../reducers/userToProcessReducer';

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    login: loginReducer,
    auth: authReducer,
    error: manageErrorReducer,
    message: messageReducer,
    callInProgress: callInProgressReducer,
    callOnHold: callOnHoldReducer,
    callHistory: callHistoryReducer,
    callManual: callManualReducer,
    share: shareReducer,
    meet: meetReducer,
    user: userReducer,
    profile: profileReducer,
    note: noteReducer,
    signup: signupReducer,
    question:questionReducer,
    usertoprocess:userToProcessReducer
});

const persistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['auth'] 
  };

  const pReducer = persistReducer(persistConfig, reducers);



const store = createStore(
    pReducer,
    composeEnhancers(
        applyMiddleware(thunk)
    )
);


const persistor = persistStore(store);

export { persistor, store };


import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMeet } from '../../../actions/meetActions';
import Jitsi from '../../shared/jitisi/Jitsi';
import Loading from '../../shared/loading/Loading';
import { routes } from '../../../enum/routes';

const MeetPatient = () => {
    const { token } = useParams();
    const { data } = useSelector(state => state.meet);
    const { sala, estadofinal, urlretorno, pacientedetalle } = data;
    const dispatch = useDispatch();
    const history = useHistory();

    const settings = {
        displayName: pacientedetalle.nombrecompleto,
    }

    const overrideMethods = {
        onOverrideHangup: () => redirectFinal(),
        onOverrideParticipantLeft: () => redirectFinal(),
    }

    const redirectFinal = () => {
        if (urlretorno === null) {
            history.push(routes.paciente_llamadas_sala_finalizada.replace(':token', token));
        } else {
            window.open(urlretorno, "_self");
        }
    }

    useEffect(() => {
        dispatch(getMeet(token));
        const interval = setInterval(() => {
            dispatch(getMeet(token));
        }, 2500);

        if (estadofinal) {
            redirectFinal();
        } else if (sala !== null && sala.dominio !=='') {
            clearInterval(interval);
        }

        return () => {
            clearInterval(interval);
        }

        

    }, [sala, estadofinal])


    return (
        <>
            {(sala === null || (sala !==null && sala.dominio === '')) ?
                <div className="flex flex-col flex-1">
                    <div className="flex flex-1 items-center justify-center">
                        <Loading message="Por favor espere, el médico lo atenderá en unos instantes..." />
                    </div>
                </div>
            :
                <Jitsi domain={sala.dominio}
                    roomId={sala.id}
                    settings={settings}
                    overrideMethods={overrideMethods}
                />
            }
        </>
    )
}

export default MeetPatient;

import { types } from "../enum/types";
import {useAxios} from "../hooks/useAxios";
import { manageError } from "./manageErrorActions";
import { authSuccess, authClear } from "./authActions";

const {sendPost,sendGet} = useAxios();


export const login =(username,password)=>{

    const requestdata = { "username": username, "password": password };

    return async (dispatch)=>{

        dispatch(loginPending());
        
        const result = await sendPost('/api/login',requestdata);
        const {auth_token,user} = result.data;

        if(result.hasError){
            dispatch(loginError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            dispatch(authClear());
            return;
        }

        const resultHome = await sendGet('/api/home',{},auth_token);

        if(resultHome.hasError){
            dispatch(manageError(result));
            dispatch(authClear());
            return;
        }

        const {permisos}= resultHome.data;

        console.log(permisos);

        dispatch(loginSuccess());
        dispatch(authSuccess(auth_token,user,permisos));
    };
};

const loginSuccess = (auth_token,user) => ({
    type: types.authLoginSuccess,
    payload: {
            auth_token,
            user:{...user}
    }
});

export const logout = () => {
    return (dispatch)=>{
        dispatch(authClear());

    }
};

const loginPending = ()=>({type: types.authLoginPending});
const loginError = (code,message)=>({type: types.authLoginError,payload:{code,message}});
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Loading from '../loading/Loading';
import JitsiButtons from './JitsiButtons';

const Jitsi = ({domain,roomId,settings, buttons, overrideMethods}) => {

    let defaultSettings = {
        displayName:'',
        password:'',
    }
    
    let defaultOverrideMethods = {
        onOverrideJoined:null,
        onOverrideHangup:null,
        onOverrideParticipantLeft:null,
        onOverrideHold:null,
    }

    defaultSettings = {...defaultSettings,...settings};
    defaultOverrideMethods = {...defaultOverrideMethods,...overrideMethods};

    const {password, displayName} = defaultSettings;
    const {
            onOverrideJoined,
            onOverrideHangup,
            onOverrideParticipantLeft,
            onOverrideHold
        } = defaultOverrideMethods;


    const [loading, setLoading] = useState(true);
    const [api, setApi] = useState(null);
    const [joined, setJoined] = useState(false);



    useEffect(() => {



        const interfaceConfig = {
            APP_NAME: 'Virtual Doctor',
            CLOSE_PAGE_GUEST_HINT: false,
            DEFAULT_REMOTE_DISPLAY_NAME:'paciente',
            DISPLAY_WELCOME_PAGE_CONTENT:false,
            HIDE_INVITE_MORE_HEADER:true,
            TOOLBAR_BUTTONS:['microphone', 'camera', 'closedcaptions', 'embedmeeting', 'fullscreen',
            'fodeviceselection', 'chat', 'raisehand',
            'videoquality', 'filmstrip', 'stats', 'mute-everyone']
        }

        const options = {
            roomName: roomId,
            width: "100%",
            height:"100%",
            parentNode: document.querySelector(`#meet${roomId}`),
            onload:handleLoad,
            configOverwrite:{disableDeepLinking:true},
            interfaceConfigOverwrite: {...interfaceConfig}
        };

        const api = new window.JitsiMeetExternalAPI(domain, options);

        api.addEventListener('participantRoleChanged', function(event) {
            if (event.role === "moderator") {
                if(password.trim()!==''){
                    api.executeCommand('password', password.trim());
                }
            }
        });

        if(onOverrideParticipantLeft!==null){
            api.addEventListener('participantLeft',onOverrideParticipantLeft);
        };


        api.addEventListener('videoConferenceLeft',function(event){
            console.log("videoConferenceLeft");
        });

        api.executeCommand('displayName', displayName);

        api.addEventListener('videoConferenceJoined', onJoined);
        

        setApi(api);

        return () => {
            api.dispose();
        };

    }, []);

    const onJoined = ()=>{
        setJoined(true);
        if(onOverrideJoined!==null){
            onOverrideJoined();
        }
    }

    const handleLoad = ()=>{
        setLoading(false);
    }

    const handleHangup = ()=>{
        api.executeCommand('hangup');
        api.dispose();
        setJoined(false);
        setApi(null);
        if(onOverrideHangup!==null){
            onOverrideHangup();
        }
    }



    return (
        <div className="flex flex-col flex-1">  
            {loading && 
                <div className="flex items-center justify-center">
                    <Loading message="Preparando la Sala..."/>
                </div>
            }
            <div className="w-full flex flex-col flex-1">
                <div id={`meet${roomId}`} className="h-screen lg:h-full"></div>
                {
                    joined && <JitsiButtons onHangup = {handleHangup} onHold={onOverrideHold}/>
                }
            </div>

        </div>
    )
}

Jitsi.propTypes = {
    domain: PropTypes.string.isRequired,
    roomId: PropTypes.string.isRequired,
    settings:PropTypes.object,
    buttons:PropTypes.object,
    overrideMethods: PropTypes.object
}

export default Jitsi

import React from 'react'
import ClockIcon from '../icons/ClockIcon'
import HangUpIcon from '../icons/HangUpIcon'

const JitsiButtons = ({onHold,onHangup}) => {



    return (
        <div className="flex flex-1 justify-center items-center">
            {onHold &&
                <div className="flex items-center justify-center flex-col m-3">
                    <button 
                        className="bg-orange-500 hover:bg-orange-700 items-center justify-center rounded-full h-10 w-10 focus:outline-none flex"
                        onClick={onHold}
                    >
                        <ClockIcon className="w-6 h-6 text-gray-100" /> 
                    </button> 
                    <span className="text-gray-600 italic text-sm">A espera</span>
                </div>
            }
            {onHangup &&
                <div className="flex items-center justify-center flex-col m-3">
                    <button 
                        className="bg-red-500 hover:bg-red-700 items-center justify-center rounded-full h-10 w-10 focus:outline-none flex"
                        onClick={onHangup}
                    >
                        <HangUpIcon className="w-6 h-6 text-gray-100" /> 
                    </button>  
                    <span className="text-gray-600 italic text-sm">Finalizar Llamada</span>
                </div>
            }

        </div>
    )
}

export default JitsiButtons

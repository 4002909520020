import React  from 'react';
import { useSelector } from 'react-redux';


import ShareCopy from './ShareCopy';
import ShareEmail from './ShareEmail';
import ShareWhatsapp from './ShareWhatsapp';

const ShareInfo = () => {

    const {data} = useSelector(state => state.callManual);
    const {token,
            urlentrada,
            textocompartir,
            paciente} = data;
    
    return (
        urlentrada && 
        <div className="flex flex-col">
            <div className="flex flex-col lg:flex-row items-center rounded-sm py-3">
                <div className="flex flex-1">
                    <p className="text-vd-light font-bold italic py-5 text-sm lg:text-lg">{urlentrada}</p>
                </div>
                <div className="flex">
                    <div className="flex flex-col items-center justify-center mr-3">
                        <ShareCopy textToCopy={urlentrada}/>
                    </div>
                    <div className="flex flex-col items-center justify-center mr-3">
                        <ShareWhatsapp token={token} textToShare={encodeURIComponent(`${textocompartir}`)} defaultWhatsapp={paciente.tel}/>
                    </div>
                    <div className="flex flex-col items-center justify-center mr-3">
                        <ShareEmail token={token}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareInfo

import { types } from "../enum/types";

const initialState = {
    pending: false,
    error: {
        code: 0,
        message: ''
    },
    user: {
        user: '',
        name: '',
        surname: '',
        email: '',
        role: '',
        birthday: '',
        tel:''
    }
    
};

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.homePending:
            return { ...state, pending: true, submit: false };
        case types.homeSuccess:
            return { ...state, pending: false, submit: true, user: { ...action.payload } };
        case types.homeError:
            return { ...state, pending: false, submit: true, error: { ...action.payload } };
        default:
            return state;
    }
}

import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getQuestions } from '../../../actions/questionActions';

const MeetQuestions = ({token}) => {

    const {data} = useSelector(state => state.question);

    const {antitermico,
    comentarios,
    fiebre,
    descripcionsintoma,
    temperatura} = data;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getQuestions(token));    
    }, [])

    return (
        descripcionsintoma!==null &&
        <div className="bg-white rounded py-2 px-2">
            <div className="flex flex-col mb-2">
                <p className="text-gray-600 text-xs">Síntoma</p>
                <p className="text-bold text-vd-dark font-bold">{descripcionsintoma}</p>
            </div>
            <div className="flex items-center mb-2">
                <p className="text-gray-600 text-xs">
                    ¿Lleva más de 48 hs con fiebre? 
                    <span className="ml-1 font-bold text-vd-dark">{fiebre}</span>
                </p>
                
            </div>
            <div className="flex items-center mb-2">
                <p className="text-gray-600 text-xs">
                    ¿Tiene más de 38° grados de temperatura?
                    <span className="ml-1 font-bold text-vd-dark">{temperatura}</span>
                </p>
            </div>
            <div className="flex items-center mb-2">
                <p className="text-gray-600 text-xs">
                    ¿La temperatura baja con antitérmicos?
                    <span className="ml-1 font-bold text-vd-dark">{antitermico}</span>
                </p>
            </div>
            <div className="flex flex-col mb-2">
                <p className="text-gray-600 text-xs">Comentarios</p>
                <p className="ml-1 text-vd-dark italic">{comentarios}</p>
            </div>
        </div>
    )
}

export default MeetQuestions

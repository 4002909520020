import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { createMeet } from '../../../actions/meetActions';
import { useParams } from 'react-router-dom';
import MeetVideoConference from './MeetVideoConference';
import MeetDetail from './MeetDetail';


const Meet = () => {
    
   const {token}= useParams();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createMeet(token));
    }, [dispatch,token])
    
    return (

      <div className="flex flex-1 flex-col lg:flex-row">
          <div className="flex flex-1 p-1">
            <MeetVideoConference  className="videoBox" />
          </div>
          <div className="p-1">
            <MeetDetail token={token}/>
          </div>
        </div>
    )
}

export default Meet;
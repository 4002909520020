import { types } from "../enum/types";

const initialState = {pending:false,
                        error:{code:0,message:''},
                        data:{
                                token:'',
                                urlentrada:'',
                                textocompartir:'',
                                paciente:{
                                    id:0,
                                    nombre: '',
                                    email:'',
                                    tel:null,
                                    fechanacimiento:null,
                                    edad:0
                                }
                            }
                    };

export const callManualReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.callManualList:
            return  {...state,pending:false,data:{...action.payload}};
        case types.callManualError:
            return  {...state,pending:false,error:{...action.payload}};
        case types.callManualPending:
            return  {...state,pending:true};
        default:
            return state;
    }
}

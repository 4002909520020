import React from 'react'
import logo from './../../../images/logos/logos-10.png'

const NavBar = () => {
    return (
        <nav className="flex bg-vd-normal">
            <div className="flex flex-col items-center justify-center">
                <img src={logo} alt="logo" width="150px" />
            </div>
        </nav>
    )
}

export default NavBar

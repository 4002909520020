import React from 'react'
import User from '../../shared/user/User'

const HomeAdmin = () => {
    return (
        <div className="flex flex-col flex-1">
            <User/>
        </div>
    )
}

export default HomeAdmin

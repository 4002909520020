import { types } from "../enum/types";
import { logout } from "./loginActions";
import { showMessage } from "./messageActions";
import messageSeverity from './../enum/messageSeverity'

export const addError=(errorCode,errorMesssage,data)=>({type: types.addError,payload:{errorCode,errorMesssage,data}});



export const manageError = (response)=>{
    const {data,errorCode,errorMessage} = response;

    return (dispatch)=>{
        if(errorCode ===401 || errorCode===403){
            dispatch(logout());
            dispatch(showMessage('Su sesión ha expirado vuelva a ingresar.',messageSeverity.error));
        }
        else if(errorCode===500){
            dispatch(showMessage('Ha ocurrido un error',messageSeverity.error));
        }
        else if (errorCode ===400){

            if(data !==null){
                dispatch(showMessage(data,messageSeverity.error));
            }
            else{
                dispatch(showMessage('Ha ocurrido un error',messageSeverity.error));
            }
            

        }

        dispatch(addError(errorCode,errorMessage,data));

    }
}
import React from 'react'

export const TableCustom = ({rowsCount,pending,columns, renderBody}) => {

    return (

    <table className="table-auto w-full bg-white">
        <thead >
            <tr className="border">
                {columns.map((col,index)=>(
                    <th key={index} className="px-4 py-2 bg-white">{col}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            <tr className="border">
                {
                    (pending && rowsCount===0) && 
                    <td colSpan={columns.length} className="text-center text-gray-600 py-6 bg-white">cargando...</td>
                }
            </tr>
            <tr className="border">
                {
                    (!pending && rowsCount===0) && 
                    <td colSpan={columns.length} className="text-center text-gray-600 py-6 bg-white">No hay resultados para mostrar</td>
                }
            </tr>
            
            {renderBody()}

        </tbody>
    </table>
    )
}

import { types } from "../enum/types";
import {useAxios} from "../hooks/useAxios";
import { manageError } from "./manageErrorActions";



const {sendPost,sendGet} = useAxios();

export const createMeet=(token)=>{

    return async (dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(createMeetPending());
        const result = await sendPost(`/consulta/crearllamada?token=${token}`,{},auth_token);
        if(result.hasError){
            dispatch(createMeetError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(createMeetSuccess(result.data));
    };
};

export const initMeet=(token)=>{

    return async (dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(initMeetPending());

        const result = await sendPost(`/consulta/iniciarllamada?token=${token}`,{},auth_token);


        if(result.hasError){
            dispatch(initMeetError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(initMeetSuccess(result.data));
    };
};

export const getMeet=(token)=>{

    return async (dispatch)=>{

        dispatch(getMeetPending());

        const result = await sendGet(`/public/getconsulta?token=${token}`,{});
        if(result.hasError){
            dispatch(getMeetError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }

        dispatch(getMeetSuccess(result.data));
    };
};




export const endMeet = ()=>({type: types.endMeet,payload:new Date()});

const createMeetSuccess=(data)=>({type: types.createMeetSuccess,payload:data});
const createMeetPending =()=>({type:types.createMeetPending});
const createMeetError =(code,message)=>({type:types.createMeetError,payload:{code,message}});


const initMeetSuccess=(data)=>({type: types.initMeetSuccess,payload:data});
const initMeetPending =()=>({type:types.initMeetPending});
const initMeetError =(code,message)=>({type:types.initMeetError,payload:{code,message}});


const getMeetSuccess=(data)=>({type: types.getMeetSuccess,payload:data});
const getMeetPending =()=>({type:types.getMeetPending});
const getMeetError =(code,message)=>({type:types.getMeetError,payload:{code,message}});



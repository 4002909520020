import React from 'react'
import useIsAuth from '../../../hooks/useIsAuth';
import User from '../../shared/user/User';
import CallManual from '../call/CallManual';
import authItem from '../../../enum/authItems'


const HomeDoctor = () => {

    const {isAuth} = useIsAuth();

    return (
        <>
        <div className="flex flex-col flex-1">
            <User/>
            
            {isAuth(authItem.consultaManual) &&
                <div className="flex flex-col flex-1 justify-center">
                    <CallManual/>
                </div>
            }

        </div>

        </>
    )
}

export default HomeDoctor;

import {  useSelector } from 'react-redux';
import role from './../enum/role';
import {routes} from './../enum/routes';
const useIsAuth = () => {

    const { isAuthenticated,permisos, user } = useSelector(state => state.auth);

    const hasAuthItem = (authItemName)=>{
        return permisos.hasOwnProperty(authItemName) && permisos[authItemName]===true;
    }

    const getRootRoute=()=>{
        switch(user.role){
            case role.doctor:
                return routes.doctor_root;
            case role.patient:
                return routes.paciente_root;
            case role.admin:
                return routes.admin_root;
            default:
                return routes.paciente_root;              
        }
    }


    const isAuth =(authItemName='')=>{
        const hasItem = authItemName ==='' || (authItemName!=='' && hasAuthItem(authItemName))?true:false;

        return  isAuthenticated && hasItem;
    }

    return {isAuth,getRootRoute};
}

export default useIsAuth;

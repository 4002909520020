import React from 'react'
import MeetQuestions from './../meet/MeetQuestions'

const CallDetail = ({detail, closePopover}) => {

    const {paciente,token} = detail;

    return (
        <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-2">
            <div className="px-2 py-2">
                <div className="font-bold text-xl mb-2">{paciente}</div>
            </div>
            <div>
                <MeetQuestions token={token} />
            </div>
            <div className="px-2 pt-4 pb-2 flex">
                <button className="text-center bg-vd-normal hover:bg-vd-dark text-white px-2 py-1 rounded"
                    onClick={closePopover}>Cerrar
                </button>
            </div>
        </div>
    )
}

export default CallDetail

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { callBackToOnHold, callInProgressFinalize, callOnHoldCancel } from '../../../actions/callActions'
import { routes } from '../../../enum/routes'
import ClockIcon from '../../shared/icons/ClockIcon'
import CrossIcon from '../../shared/icons/CrossIcon'
import HangUpIcon from '../../shared/icons/HangUpIcon'
import PhoneIcon from '../../shared/icons/PhoneIcon'
import Modal from '../../shared/modal/Modal'

const CallButtons = ({onHold,detail}) => {

    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const {token,paciente} = detail;

    const handleCancel=()=>{
        setShowModal(true);

    }
    
    const handleTrashCall = ()=>{
        dispatch(callOnHoldCancel(token));
        setShowModal(false);

    }

    const handleCall = ()=>{
        history.push(routes.doctor_llamadas_sala.toString().replace(':token',token));
    }

    const handleToOnHold = ()=>{
        dispatch(callBackToOnHold(token));
    }

    const handleFinalize =()=>{
        dispatch(callInProgressFinalize(token));
    }

    return (
        onHold?
        <>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold px-1 py-2 rounded flex flex-1 mx-1"
                onClick={handleCancel}>
                <CrossIcon className="h-4 w-4 text-white mr-1"/>
                <span className="text-gray-200 text-xs">Cancelar</span>
            </button>
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold px-1 py-2 rounded flex flex-1 mx-1"
                onClick={handleCall}>
                <PhoneIcon className="h-4 w-4 text-white mr-1"/>
                <span className="text-gray-200 text-xs">Atender</span>
            </button>

            <Modal setShowModal={setShowModal} 
                    showModal={showModal} 
                    title={`Llamada con ${paciente}`}
                    body={()=>('¿Está seguro de cancelar la llamada?')}
                    handleActionOk={handleTrashCall}
            />
        </>
        :
        <>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold px-1 py-2 rounded flex flex-1 mx-1"
                onClick={handleFinalize}>
                <HangUpIcon className="h-4 w-4 text-white mr-1"/>
                <span className="text-gray-200 text-xs">Finalizar</span>
            </button>
            <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold px-1 py-2 rounded flex flex-1 mx-1"
                onClick={handleToOnHold}>
                <ClockIcon className="h-4 w-4 text-white mr-1"/>
                <span className="text-gray-200 text-xs">Espera</span>
            </button>
            <button className="bg-green-500 hover:bg-green-700 text-white font-bold px-1 py-2 rounded flex flex-1 mx-1"
                onClick={handleCall}>
                <PhoneIcon className="h-4 w-4 text-white mr-1"/>
                <span className="text-gray-200 text-xs">Llamada</span>
            </button>
        </>
    )
}

export default CallButtons

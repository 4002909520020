import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import {changePasswordAction} from '../../../actions/profileActions';

const ChangePassword = () => {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPasswordError, setOldPasswordError] = useState('');
    const [newPasswordError, setNewPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const {pendingChangePassword} = useSelector(state => state.profile);
    const {data} = useSelector(state => state.user);
    const dispatch = useDispatch();

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(!validateForm()){
            const dataRequest ={
                user: data.user,
                oldpassword: oldPassword,
                newpassword: newPassword
            }

            dispatch(changePasswordAction(dataRequest));
            clearForm();
        }


    }

    const clearForm = ()=>{
        setNewPasswordError('');
        setOldPassword('');
        setConfirmPasswordError('');

        setNewPassword('');
        setOldPassword('');
        setConfirmPassword('');
    }

    const validateForm = ()=>{
        let error = false;
        setNewPasswordError('');
        setOldPassword('');
        setConfirmPasswordError('');

        if(validator.isEmpty(oldPassword)){
            setOldPasswordError('Ingrese su contraseña actual');
            error = true;
        }

        if(validator.isEmpty(newPassword)){
            setNewPasswordError('Ingrese la nueva contraseña');
            error = true;
        }
        else if (!validator.isLength(newPassword,{min:6})){
            setNewPasswordError('Ingrese al menos 6 caracteres');
            error = true;
        }

        if(!validator.equals(newPassword,confirmPassword)){
            setConfirmPasswordError('Las contraseñas no coinciden');
            error=true;
        }

        return error;

    }

    return (
        <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-3/4" onSubmit={handleSubmit}>
            <h2 className="text-vd-normal text-2xl text-right my-6 font-bold">Modificar Contraseña</h2>

            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full lg:w-4/12 px-3 mb-6 lg:mb-0">
                <label className={`block text-gray-700 text-sm font-bold mb-2 ${oldPasswordError!==''?'text-red-700':''}`} 
                    htmlFor="oldpassword">
                    Contraseña Actual
                </label>
                <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                    ${oldPasswordError!==''?'border-red-300 text-red-700':''}`} 
                        id="oldpassword" 
                        type="password" 
                        placeholder=""
                        maxLength="50"
                        value={oldPassword}
                        onChange={({target})=>setOldPassword(target.value)}
                />
                {oldPasswordError !=='' && <p className="text-red-500 text-xs italic">{oldPasswordError}</p>}
                </div>


                <div className="w-full lg:w-4/12 px-3 mb-6 lg:mb-0">
                <label className={`block text-gray-700 text-sm font-bold mb-2 ${newPasswordError!==''?'text-red-700':''}`} 
                    htmlFor="newpassword">
                    Contraseña Nueva
                </label>
                <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                    ${newPasswordError!==''?'border-red-300 text-red-700':''}`} 
                        id="newpassword" 
                        type="password" 
                        placeholder=""
                        maxLength="50"
                        value={newPassword}
                        onChange={({target})=>setNewPassword(target.value)}
                />
                {newPasswordError !=='' && <p className="text-red-500 text-xs italic">{newPasswordError}</p>}
                </div>

                <div className="w-full lg:w-4/12 px-3 mb-6 lg:mb-0">
                <label className={`block text-gray-700 text-sm font-bold mb-2 ${newPasswordError!==''?'text-red-700':''}`} 
                    htmlFor="confirmPassword">
                    Repita la Contraseña
                </label>
                <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                    ${confirmPasswordError!==''?'border-red-300 text-red-700':''}`} 
                        id="confirmPassword" 
                        type="password" 
                        placeholder=""
                        maxLength="50"
                        value={confirmPassword}
                        onChange={({target})=>setConfirmPassword(target.value)}
                />
                {confirmPasswordError !=='' && <p className="text-red-500 text-xs italic">{confirmPasswordError}</p>}
                </div>

            </div>

            <div className="flex flex-row justify-end flex-wrap -mx-3 mb-6">
                <div className="w-full lg:w-4/12 px-3 mb-6 lg:mb-0 flex">
                    <button className={`w-full bg-vd-normal hover:bg-vd-dark text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline
                                                ${pendingChangePassword?'opacity-50 cursor-not-allowed':''}`} 
                                    type="submit"
                                    disabled={pendingChangePassword}>
                            {pendingChangePassword?'Aguarde...':'Cambiar'}                 
                    </button>
                </div>
            </div>
        </form>    
    )
}

export default ChangePassword

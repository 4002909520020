import { types } from "../enum/types";
import { createFalse } from "typescript";

const initialState = {pending:false,error:{code:0,message:''},data:{
                                                                    antitermico: null,
                                                                    comentarios: null,
                                                                    fiebre: null,
                                                                    idsintoma: null,
                                                                    descripcionsintoma:null,
                                                                    temperatura: null,
                                                                    token: null}
};

export const questionReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.getQuestionPending:
            return  {...state,pending:true};
        case types.getQuestionSuccess:
                return  {...state,pending:false,data:{...action.payload}};
        case types.getQuestionError:
                return  {...state,pending:createFalse,error:{...action.payload}};
        default:
            return state;
    }
}
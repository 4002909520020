import { types } from "../enum/types";

const initialState = {pending:false,error:{code:0,message:''},data:[]};

export const callInProgressReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.callInProgressList:
            return  {...state,pending:false,data:[...action.payload]};
        case types.callInProgressListError:
            return  {...state,pending:false,error:{...action.payload}};
        case types.callInProgressListPending:
            return  {...state,pending:true};
        case types.callRemoveToInProgress:
            const dataInProgress = state.data.filter(x=>x.token !== action.payload);
            return  {...state,data:dataInProgress};
        default:
            return state;
    }
}

import React, { useState } from 'react'
import logo from './../../../images/logos/logos-10.png';
import logoSM from './../../../images/logos/logos-06.png';
import MenuIcon from '../icons/MenuIcon';
import PowerOff from '../icons/PowerOff';
import { useDispatch } from 'react-redux';
import { authClear } from '../../../actions/authActions';

const SideBar = ({menuTop,menuBottom}) => {
    
    const [showMenu, setShowMenu] = useState(false);
    const dispatch = useDispatch();
    
    return (
        <div className="bg-vd-normal  w-full lg:w-1/5 flex-col items-center flex">
            <div className="flex flex-col w-full lg:h-screen">
                <div className="border-b border-gray-200 lg:w-full flex items-center">
                    <div className="flex flex-1 lg:justify-center items-center">
                        <img src={logo} alt="logo" width="200" className="hidden lg:block"/>
                        <img src={logoSM} alt="logo" className="block lg:hidden"/>
                    </div>
                    <div className="flex items-end mr-2 lg:hidden">
                        <button className="border border-gray-100 rounded p-2"
                                onClick={()=>setShowMenu(!showMenu)}>
                            <MenuIcon className="w-6 h-6 text-gray-100"/>
                        </button>
                    </div>
                </div>
                <nav className={`w-full flex-col flex-1 lg:flex ${showMenu?'flex':'hidden'}`}>
                    <div className="flex flex-col flex-1">
                        {menuTop?menuTop():null}
                    </div>

                    <div className="flex flex-col mb-4">
                        {menuBottom?menuBottom():null}

                        <button 
                        className="text-gray-200 hover:bg-vd-dark p-2 tracking-widest text-left focus:outline-none flex"
                        onClick={()=>dispatch(authClear())}
                        >
                        <PowerOff className="w-6 h-6 text-gray-100 mr-4"/>
                        Salir
                        </button>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default SideBar
   

import React from 'react'
import { Provider } from 'react-redux';

import { store } from './store/store';
import { MainRouter } from './routers/MainRouter'

export const App = () => {
    return (
        <Provider store={ store }>
            <MainRouter />
        </Provider>

    )
}

export default App;
import React, { useEffect, useState } from 'react'
import MailIcon from '../icons/MailIcon'
import Modal from '../modal/Modal'
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { shareEmailAction } from '../../../actions/shareActions';


const ShareEmail = ({token}) => {

    const [showModal, setShowModal] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [send, setSend] = useState(false);
    const dispatch = useDispatch();
    const {pending} = useSelector(state => state.share);

    useEffect(() => {
        if(send && !pending){
            setShowModal(false);
        }
    }, [send,pending])


    const validateFormEmail =()=>{
        if(validator.isEmpty(email)){
            setEmailError('Ingrese un email');
            return false;
        }
        else if(!validator.isEmail(email)){
            setEmailError('Ingrese un email válido');
            return false;
        }
        return true;
    }

    const shareFormEmail = ()=>(
        <div className="flex flex-col">
                        <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                                ${emailError!==''?'border-red-300 text-red-700':''}`} 
                                    id="email" 
                                    type="text" 
                                    placeholder="paciente@mail.com"
                                    maxLength="50"
                                    value={email}
                                    onChange={({target})=>setEmail(target.value)}
                        />
                        {emailError !=='' && <p className="text-red-500 text-xs italic">{emailError}</p>}
        </div>
    )

    const handleEmail=()=>{
        if(!validateFormEmail()){
            return;
        }
        setSend(true);
        dispatch(shareEmailAction(token,email));
    }
    
    return (
        <>
            <button 
                className="rounded-full bg-gray-500 hover:bg-gray-600 focus:outline-none w-10 h-10 flex items-center justify-center"
                onClick={()=>setShowModal(true)}
            >
            <MailIcon className="w-6 h-6 text-gray-100"/>
            </button>
            <p className="text-sm text-gray-500">e-mail</p>

            <Modal setShowModal={setShowModal} 
                    showModal={showModal} 
                    title={'Enviar por Email'}
                    body={shareFormEmail}
                    handleActionOk={handleEmail}
                    titleButtonOk='Enviar'
                    pending={pending}
            />
        </>
    )
}

export default ShareEmail

import React from 'react'
import SideBar from '../../shared/sidebar/SideBar';
import MenuBottomDoctor from '../menu/MenuBottomDoctor';
import MenuTopDoctor from '../menu/MenuTopDoctor';

const LayoutDoctor = ({children}) => {
    
    return (

        <div className="h-screen bg-gray-200 flex flex-col lg:flex-row">
          <SideBar  menuTop={MenuTopDoctor} menuBottom={MenuBottomDoctor}/>
          <div className="flex-1 flex flex-col  overflow-y-scroll overflow-x-hidden p-2">
            {children}
          </div>
        </div>
    )
}

export default LayoutDoctor;

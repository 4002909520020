import { types } from '../enum/types';
import {useAxios} from "../hooks/useAxios";
import { manageError } from './manageErrorActions';

const {sendGet} = useAxios();

export const homeData=()=>{

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(homePending());
        const result = await sendGet('/api/home',{},auth_token);
        
        if(result.hasError){
            dispatch(homeError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(homeSuccess(result.data.user));
    };
};

const homeSuccess =(data)=>({type:types.homeSuccess,payload:data});
const homePending =()=>({type:types.homePending});
const homeError =(code,message)=>({type:types.homeError,payload:{code,message}});
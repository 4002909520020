import { types } from '../enum/types';
import {useAxios} from "../hooks/useAxios";
import { manageError } from './manageErrorActions';

const {sendGet,sendPost} = useAxios();

export const callHistory=()=>{

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(callHistoryPending());
        const result = await sendGet('/consulta/historia?pag=0',{},auth_token);

        if(result.hasError){
            dispatch(callHistoryError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }

        dispatch(callHistorySuccess(result.data));
    };
};

export const callHistoryNext=(page)=>{

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(callHistoryNextPending());
        const result = await sendGet(`/consulta/historia?pag=${page}`,{},auth_token);

        if(result.hasError){
            dispatch(callHistoryNextError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(callHistoryNextSuccess(result.data));
    };
};

const callHistorySuccess =(data)=>({type:types.callHistoryList,payload:data});
const callHistoryPending =()=>({type:types.callHistoryPending});
const callHistoryError =(code,message)=>({type:types.callHistoryError,payload:{code,message}});

const callHistoryNextSuccess =(data)=>({type:types.callHistoryNextList,payload:data});
const callHistoryNextPending =()=>({type:types.callHistoryNextPending});
const callHistoryNextError =(code,message)=>({type:types.callHistoryNextError,payload:{code,message}});
import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { routes } from '../enum/routes'
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';

import LayoutDoctor from '../components/doctor/layout/LayoutDoctor';
import LayoutPacient from '../components/patient/layout/LayoutPacient';
import HomeDoctor from '../components/doctor/home/HomeDoctor';
import Meet from '../components/doctor/meet/Meet';
import MeetPatient from '../components/patient/meet/MeetPatient';
import MeetPatientEnd from '../components/patient/meet/MeetPatientEnd';
import { Message } from '../components/shared/message/Message';
import  Login  from '../components/shared/auth/Login';
import CallHistory from '../components/doctor/call/CallHistory';
import CheckIn from '../components/patient/checkin/CheckIn';
import Questions from '../components/patient/checkin/Questions';
import EmptyLayout from '../components/shared/emptylayout/EmptyLayout';
import LayoutDoctorMeet from '../components/doctor/layout/LayoutDoctorMeet';
import Profile from '../components/shared/profile/Profile';
import ForgotPassword from '../components/shared/auth/ForgotPassword';
import SignUp from '../components/shared/auth/SignUp';
import SignUpSuccess from '../components/shared/auth/SignUpSuccess';
import LayoutAdmin from '../components/admin/layout/LayoutAdmin';
import HomeAdmin from '../components/admin/home/HomeAdmin';
import UsersAdmin from '../components/admin/users/UsersAdmin';

export const MainRouter = () => {

    return (
        <Router>
            <Switch>

                <PublicRoute exact
                    path={routes.login}
                    component={Login}
                    layout={EmptyLayout}>
                </PublicRoute>

                <PublicRoute exact
                    path={routes.forgot_password}
                    component={ForgotPassword}
                    layout={EmptyLayout}>
                </PublicRoute>
                
                <PublicRoute exact
                    path={routes.signup}
                    component={SignUp}
                    layout={EmptyLayout}>
                </PublicRoute>

                <PublicRoute exact
                    path={routes.signup_success}
                    component={SignUpSuccess}
                    layout={EmptyLayout}>
                </PublicRoute>

                <PublicRoute exact
                    path={routes.paciente_llamadas_sala}
                    component={MeetPatient}
                    layout={LayoutPacient}>
                </PublicRoute>

                <PublicRoute exact
                    path={routes.paciente_llamadas_sala_finalizada}
                    component={MeetPatientEnd}
                    layout={LayoutPacient}>
                </PublicRoute>

                <PublicRoute exact
                    path={routes.paciente_checkin}
                    component={CheckIn}
                    layout={LayoutPacient}>
                </PublicRoute>

                <PublicRoute exact
                    path={routes.paciente_checkin_questions}
                    component={Questions}
                    layout={LayoutPacient}>
                </PublicRoute>

                <PrivateRoute exact
                    path={routes.root}
                    component={HomeDoctor}
                    layout={LayoutDoctor}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.doctor_inicio}
                    component={HomeDoctor}
                    layout={LayoutDoctor}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.doctor_perfil}
                    component={Profile}
                    layout={LayoutDoctor}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.doctor_llamadas}
                    component={CallHistory}
                    layout={LayoutDoctor}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.doctor_llamadas_sala}
                    component={Meet}
                    layout={LayoutDoctorMeet}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.admin_root}
                    component={HomeAdmin}
                    layout={LayoutAdmin}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.admin_inicio}
                    component={HomeAdmin}
                    layout={LayoutAdmin}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.admin_perfil}
                    component={Profile}
                    layout={LayoutAdmin}>
                </PrivateRoute>

                <PrivateRoute exact
                    path={routes.admin_users}
                    component={UsersAdmin}
                    layout={LayoutAdmin}>
                </PrivateRoute>

            </Switch>
            <Message />
        </Router>
    )
}

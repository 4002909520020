import { types } from "../enum/types";
import {useAxios} from "../hooks/useAxios";
import { manageError } from "./manageErrorActions";

const {sendPost} = useAxios();

export const getQuestions=(token)=>{

    return async (dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(getQuestionPending());

        const result = await sendPost(`/consulta/obtenercuestionario?token=${token}`,{},auth_token);
        if(result.hasError){
            dispatch(getQuestionError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }

        dispatch(getQuestionSuccess(result.data));
    };
};


const getQuestionSuccess=(data)=>({type: types.getQuestionSuccess,payload:data});
const getQuestionPending =()=>({type:types.getQuestionPending});
const getQuestionError =(code,message)=>({type:types.getQuestionError,payload:{code,message}});
import React, { useEffect, useState } from 'react'
import WhatsappIcon from '../icons/WhatsappIcon';
import Modal from '../modal/Modal';
import validator from 'validator';
import { useDispatch } from 'react-redux';
import { shareWhatsappAction } from '../../../actions/shareActions';

const ShareWhatsapp = ({token,textToShare,defaultWhatsapp}) => {

    useEffect(() => {
        //setPhone(defaultWhatsapp);
    }, [defaultWhatsapp]);

    const [showModal, setShowModal] = useState(false);
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [delayTime, setDelayTime] = useState(5);
    const [send, setSend] = useState(false);
    const [titleSend, setTitleSend] = useState('Enviar');

    const dispatch = useDispatch();


    const validateFormWhatsapp =()=>{
        if(validator.isEmpty(phone)){
            setPhoneError('Ingrese un whatsapp');
            return false;
        }
        return true;
    }

    useEffect(() => {
        if(!send){
            return;
        }

        const interval = setInterval(() => {
            setDelayTime(delayTime-1)
            setTitleSend(`Abriendo Whatsapp en ${delayTime} seg.`);
        }, 1000);

        if(delayTime===0){
            clearInterval(interval);
            window.open(`https://wa.me/${phone}?text=${textToShare}`,'_blank');
            setTitleSend('Enviar');
            setDelayTime(5);
            setSend(false);
            setShowModal(false);
        }

        return () => {
            clearInterval(interval);
        }
    }, [delayTime,send])

    const handleWhatsapp=()=>{

        if(!validateFormWhatsapp()){
            return;
        }

        setSend(true);

        dispatch(shareWhatsappAction(token,phone));
    }

    const shareFormWhatsapp = ()=>(
        <div className="flex flex-col">
            <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                    ${phoneError!==''?'border-red-300 text-red-700':''}`} 
                        id="phone" 
                        type="text" 
                        placeholder="+54 11 12345678"
                        maxLength="50"
                        value={phone}
                        onChange={({target})=>setPhone(target.value)}
            />
            {phoneError !=='' && <p className="text-red-500 text-xs italic">{phoneError}</p>}

        </div>
    )

    return (
        <>
         <button
            className="rounded-full bg-gray-500 hover:bg-gray-600 focus:outline-none w-10 h-10 flex items-center justify-center"
            onClick={()=>setShowModal(true)}
        >
            <WhatsappIcon className="w-6 h-6 text-gray-100"/>
        </button>
        <p className="text-sm text-gray-500">whatsapp</p>

        <Modal setShowModal={setShowModal} 
                    showModal={showModal} 
                    title={'Compartir en Whatsapp'}
                    body={shareFormWhatsapp}
                    handleActionOk={handleWhatsapp}
                    titleButtonOk={titleSend}
            />
        </>
    )
}

export default ShareWhatsapp

import React, { useEffect, useState } from 'react'
import logo from './../../../images/logos/logos-05.png';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import {signupAction} from './../../../actions/signupActions';
import { useHistory } from 'react-router-dom';
import {routes} from './../../../enum/routes';

const SignUp = () => {

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [nameError, setNameError] = useState('');
    const [surnameError, setSurnameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [send, setSend] = useState(false);

    const dispatch = useDispatch();
    const {pending,error} = useSelector(state => state.signup);
    const history = useHistory();

    const handleSubmit = (e)=>{
        e.preventDefault();
        const error = validateForm();

        if(!error){
            
            const data = {
                nombre:name,
                apellido:surname,
                email:email,
                tel:phone
            }
            setSend(true);
            dispatch(signupAction(data));

        }
    }

    useEffect(() => {
        if(send && !pending && error.code===0){
            history.push(routes.signup_success);
        }
    }, [send,pending,error.code,history]);

    const clearError=()=>{
        setNameError('');
        setSurnameError('');
        setEmailError('');
        setPhoneError('');
    }

    const validateForm = ()=>{
        clearError();
        let error = false;

        if(validator.isEmpty(name)){
            setNameError('Ingrese un nombre');
            error= true;
        }
        if(validator.isEmpty(surname)){
            setSurnameError('Ingrese un apellido');
            error= true;
        }

        if(validator.isEmpty(email)){
            setEmailError('Ingrese un email');
            error= true;
        }
        else if(!validator.isEmail(email)){
            setEmailError('Ingrese un email válido');
            error= true;
        }

        return error;

    }

    return (
        <div className="flex flex-col bg-gray-200 items-center h-screen overflow-auto">
            <div className="flex justify-center">
                <img src={logo} alt="logo" width="200px"></img>
            </div>            
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 my-4 w-3/4 lg:w-1/2 " onSubmit={handleSubmit}>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <p className="text-gray-500 italic text-sm">* Campos obligatorios</p>
                </div>
                <div className="flex flex-wrap -mx-3">
                    <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${nameError!==''?'text-red-700':''}`} 
                        htmlFor="name">
                        Nombre *
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${nameError!==''?'border-red-300 text-red-700':''}`} 
                            id="name" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={name}
                            onChange={({target})=>setName(target.value)}
                    />
                    {nameError !=='' && <p className="text-red-500 text-xs italic">{nameError}</p>}
                    </div>


                    <div className="w-full lg:w-1/2 px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${surnameError!==''?'text-red-700':''}`} 
                        htmlFor="surname">
                        Apellido *
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${surnameError!==''?'border-red-300 text-red-700':''}`} 
                            id="surname" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={surname}
                            onChange={({target})=>setSurname(target.value)}
                    />
                    {surnameError !=='' && <p className="text-red-500 text-xs italic">{surnameError}</p>}
                    </div>

                </div>

                <div className="flex flex-wrap -mx-3">
                    <div className="w-full px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${emailError!==''?'text-red-700':''}`} 
                        htmlFor="email">
                        e-mail *
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${emailError!==''?'border-red-300 text-red-700':''}`} 
                            id="email" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={email}
                            onChange={({target})=>setEmail(target.value)}
                    />
                    {emailError !=='' && <p className="text-red-500 text-xs italic">{emailError}</p>}
                    </div>


                    <div className="w-full px-3 mb-6 lg:mb-0">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${phoneError!==''?'text-red-700':''}`} 
                        htmlFor="phone">
                        Teléfono
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                        ${phoneError!==''?'border-red-300 text-red-700':''}`} 
                            id="phone" 
                            type="text" 
                            placeholder=""
                            maxLength="50"
                            value={phone}
                            onChange={({target})=>setPhone(target.value)}
                    />
                    {phoneError !=='' && <p className="text-red-500 text-xs italic">{phoneError}</p>}
                    </div>

                </div>

                <div className="flex flex-row justify-end flex-wrap -mx-3 my-6">
                    <div className="w-full lg:w-4/12 px-3 mb-6 lg:mb-0 flex">
                        <button className={`w-full bg-vd-normal hover:bg-vd-dark text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline
                                                    ${pending?'opacity-50 cursor-not-allowed':''}`} 
                                        type="submit"
                                        disabled={pending}>
                                {pending?'Aguarde...':'Registrarme'}                 
                        </button>
                    </div>
                </div>


            </form>
        </div>
    )
}

export default SignUp

import { types } from "../enum/types";

const initialState = {  pending:false,
                        error:{code:0,message:''},
                    };

export const signupReducer = (state=initialState, action) => {
    switch (action.type) {

        case types.signup:
            return  {...state,pending:false};
        case types.signupError:
            return  {...state,pending:false,error:{...action.payload}}; 
        case types.signupPending:
            return  {...state,pending:true};
        default:
            return state;

    }
};
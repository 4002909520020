import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import useIsAuth from '../hooks/useIsAuth';

export const PublicRoute = ({
    authItem='',
    component: Component,
    layout: Layout,
    ...rest
}) => {

    const {isAuth,getRootRoute} = useIsAuth();

    return (
        ( !isAuth(authItem) ) ?
        <Layout>
            <Route {...rest} component={Component}/>
        </Layout>:
        <Redirect to={getRootRoute()} />
    )
}

PublicRoute.propTypes = {
    component: PropTypes.func.isRequired,
    layout:PropTypes.func.isRequired
}

import React from 'react'
import NavBar from '../../shared/navbar/NavBar'

const LayoutDoctorMeet = ({children}) => {
    return (
        <div className="flex flex-1 flex-col bg-gray-200 h-screen">
            <NavBar/>
            <div className="flex flex-1">
                {children}
            </div>
        </div>
    )
}

export default LayoutDoctorMeet

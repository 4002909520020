import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { homeData } from '../../../actions/homeActions';

const User = () => {
    const {user} = useSelector(state => state.user);
    const { name, surname, email } = user;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(homeData());
    }, [])
    
    return (
        <>
            <div className="flex flex-row items-center justify-center">
                <div className="flex flex-col items-center bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-8 w-full sm:w-1/2 md:w-1/2 lg:w-2/5 xl:w-2/5">
                    <p className="font-bold text-2xl text-vd-normal">{name} {surname}</p> 
                    <p className="text-gray-600">{email}</p>
                </div>
            </div> 
        </>
    )
}

export default User

import React, { useState } from 'react';
import logo from './../../../images/logos/logos-05.png';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { login } from '../../../actions/loginActions';
import { Link } from 'react-router-dom';
import { routes } from '../../../enum/routes';

const Login = () => {

    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const [userError, setUserError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    
    const dispatch = useDispatch();
    const {pending} = useSelector(state => state.login);

    const handleUserChange=({target})=>{
        setUser(target.value);
    }
    
    const handlePasswordChange=({target})=>{
        setPassword(target.value);
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        const error = validateForm();

        if(!error){
            dispatch(login(user,password));
        }
    }

    const validateForm = ()=>{

        let error = false;
        setUserError('');
        setPasswordError('');

        if(validator.isEmpty(user)){
            setUserError('El usuario es requerido.');
            error = true;
        }

        if(validator.isEmpty(password)){
            setPasswordError('La contraseña es requerida');
            error = true;
        }

        return error;
    };



    return (
            <div className="flex bg-gray-200 items-center justify-center h-screen">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-8 w-full sm:w-1/2 md:w-1/2 lg:w-w-2/5 xl:w-2/5" 
                    onSubmit={handleSubmit}
            >
                <div className="flex justify-center">
                    <img src={logo} alt="logo" width="300px"></img>
                </div>
                <div className="mb-4">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${userError!==''?'text-red-700':''}`} 
                        htmlFor="username">
                        Usuario
                    </label>
                    <input  className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                       ${userError!==''?'border-red-300 text-red-700':''}`} 
                            id="username" 
                            type="text" 
                            placeholder="vdmedic1"
                            maxLength="50"
                            autoComplete="username"
                            value={user}
                            onChange={handleUserChange}
                    />
                    {userError !=='' && <p className="text-red-500 text-xs italic">{userError}</p>}
                </div>
                <div className="mb-6">
                    <label className={`block text-gray-700 text-sm font-bold mb-2 ${passwordError!==''?'text-red-700':''}`}  
                            htmlFor="password"
                    >
                        Contraseña
                    </label>
                    <input className={`appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline
                                    ${userError!==''?'border-red-300 text-red-700':''}`}
                        id="password" 
                        type="password" 
                        placeholder="******************"
                        maxLength="50"
                        autoComplete='current-password'
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    {passwordError !=='' && <p className="text-red-500 text-xs italic">{passwordError}</p>}
                    
                    <div className="flex justify-end">
                        <Link to={routes.forgot_password} className="text-vd-normal text-sm">Olvidé mi contraseña</Link>
                    </div>
                    

                </div>
                <div className="flex">
                    <button className={`bg-vd-normal hover:bg-vd-dark text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full 
                                        ${pending?'opacity-50 cursor-not-allowed':''}`} 
                            type="submit"
                            disabled={pending}>
                    {pending?'Aguarde...':'Ingresar'}                 
                    </button>
                </div>

                <div className="flex mt-10 justify-center items-center">
                    <p className="text-gray-500">¿No tienes cuenta en Virtual Doctor? <Link to={routes.signup} className="text-vd-normal font-bold ml-1">Registrarme</Link></p>
                </div>

            </form>
        </div>
    )
}

export default Login;

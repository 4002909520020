import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { routes } from '../../../enum/routes';
import CalendarIcon from '../../shared/icons/CalendarIcon';
import HomeIcon from '../../shared/icons/HomeIcon';
import CallStack from '../call/CallStack';

const MenuTopDoctor = () => {

    const [selectedMenu, setSelectedMenu] = useState(routes.doctor_inicio);
    
    return (
        <>
            <Link to={routes.doctor_inicio} 
                  className={`flex text-white hover:bg-vd-dark p-2 tracking-widest ${selectedMenu===routes.doctor_inicio?'font-bold':''}`}
                  onClick={() => {
                                  setSelectedMenu(routes.doctor_inicio)
                          }}
            >
              <HomeIcon className="w-6 h-6 text-gray-100 mr-4"/>
              Inicio
            </Link>
            <Link to={routes.doctor_llamadas} 
                  className={`flex text-white hover:bg-vd-dark p-2 tracking-widest ${selectedMenu===routes.doctor_llamadas?'font-bold':''}`}
                  onClick={() => {
                                  setSelectedMenu(routes.doctor_llamadas)
                          }}
            >
              <CalendarIcon className="w-6 h-6 text-gray-100 mr-4"/>
              Historial
            </Link> 
            <div className="flex flex-col flex-1 overflow-y-auto lg:h-64">
                <CallStack />
            </div>
  
        </>
    )
}

export default MenuTopDoctor

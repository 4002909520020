import React, {useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { callOnHoldList, callInProgressList } from '../../../actions/callActions';

import CallStackItem from './CallStackItem';

const CallStack = () => {

    const onHold = useSelector(state => state.callOnHold);
    const inProgress = useSelector(state =>state.callInProgress);
    const dispatch = useDispatch();

    const {data:dataOnHold}=onHold;
    const {data:dataInProgress}=inProgress;

    useEffect(() => {
        dispatch(callOnHoldList());
        dispatch(callInProgressList());
        const interval = setInterval(() => {
            console.log("call stack list");
            dispatch(callOnHoldList());
            dispatch(callInProgressList());
        }, 5000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <>
            <p className="text-gray-200 p-2 tracking-widest font-bold block text-center focus:outline-none border-t bg-vd-normal">
                Cola de Pacientes
            </p>
            <div className="overflow-y-auto overflow-x-hidden">
                <div className="mb-2">
                    {dataInProgress.map(row=>
                            <CallStackItem key={row.token} detail={row} onHold={false} />
                        )
                    }
                </div>

                <div>
                    {dataOnHold.map(row=>
                            <CallStackItem key={row.token} detail={row} onHold={true} />
                        )
                    }
                </div>
                

                {
                    (dataInProgress.length ===0 && dataOnHold.length ===0) && 
                        <p className="text-gray-100 text-xs text-center">No hay pacientes en este momento.</p>
                }
            </div>
        </>
    )
    
}

export default CallStack;
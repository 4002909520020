import { types } from "../enum/types";

const initialState = {pending:false,error:{code:0,message:''},data:{pagina:0,consultas:[]}};

export const callHistoryReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.callHistoryList:
            return  {...state,pending:false,data:{pagina: action.payload.pagina, consultas:[...action.payload.consultas]}};
        case types.callHistoryError:
            return  {...state,pending:false,error:{...action.payload}};
        case types.callHistoryPending:
            return  {...state,pending:true};
        case types.callHistoryNextList:
                return  {...state,pending:false,data:{pagina: action.payload.pagina, consultas:[...state.data.consultas,...action.payload.consultas]}};
            case types.callHistoryNextError:
                return  {...state,pending:false,error:{...action.payload}};
            case types.callHistoryNextPending:
                return  {...state,pending:true};
        default:
            return state;
    }
}

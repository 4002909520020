import React from 'react'
import {dateFormat}  from '../../../helpers/DateFormat';
import MobileIcon from '../../shared/icons/MobileIcon';
import MailIcon from '../../shared/icons/MailIcon';
import CalendarIcon from '../../shared/icons/CalendarIcon';

const MeetPacientDetail = ({pacientedetalle}) => {

    const {nombre,email,tel,fechanacimiento,edad} = pacientedetalle;

    return (
        <div className="bg-white rounded py-8 px-2">
          <p className="font-bold text-2xl mb-4 text-gray-700">{nombre}</p>
          {email!==nombre && 
            <p className="flex text-gray-700">
              <MailIcon className="w-6 h-6 mr-1 text-gray-700"/> 
              {email}
            </p>
          }
          {tel !==null && 
            <p className="flex text-gray-700">
              <MobileIcon className="w-6 h-6 mr-1 text-gray-700"/> 
              {tel}
            </p>
          }
          {fechanacimiento!==null && 
            <p className="flex text-gray-700">
              <CalendarIcon className="w-6 h-6 mr-1 text-gray-700"/>
                {dateFormat(fechanacimiento)} ({edad} años) 
            </p>
          }
        </div>

    )
}

export default MeetPacientDetail

import React from 'react'
import Jitsi from './../../shared/jitisi/Jitsi';
import { useSelector, useDispatch } from 'react-redux';
import { initMeet, endMeet } from '../../../actions/meetActions';
import { callBackToOnHold, callInProgressFinalize } from '../../../actions/callActions';
import { useHistory } from 'react-router-dom';
import { routes } from './../../../enum/routes';

const MeetVideoConference = () => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.meet);
    const { sala, medico, token } = data;
    const history = useHistory();


    const settings = {
        displayName: medico,
    }

    const buttons = {
        holdButton: true,
        hangupButton: true,

    }

    const overrideMethods = {
        onOverrideHangup: () => (hangup()),
        onOverrideJoined: () => (roomJoined()),
        onOverrideHold: () => (hold())
    }


    const roomJoined = () => {
        console.log(`joined to: ${sala.url}`);
        dispatch(initMeet(token));
    };

    const hangup = () => {
        dispatch(callInProgressFinalize(token));
        dispatch(endMeet());
        history.push(routes.doctor_inicio);
    }

    const hold = () => {
        dispatch(callBackToOnHold(token));
        history.push(routes.doctor_inicio);
    }

    return (
        <>
            <div className="flex flex-1">
                {sala.id !== "" &&
                    <Jitsi
                        domain={sala.dominio}
                        roomId={sala.id}
                        settings={settings}
                        buttons={buttons}
                        overrideMethods={overrideMethods}
                    ></Jitsi>}
            </div>
        </>
    )
}

export default MeetVideoConference;
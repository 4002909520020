import React from 'react'

const EmptyLayout = ({children}) => {
    return (
        <>
            {children}   
        </>
    )
}

export default EmptyLayout

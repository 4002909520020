import { types } from "../enum/types";

const initialState = {
    pending:false,
    error:{code:0,message:''}
};

export const shareReducer = (state=initialState, action) => {
    switch (action.type) {
        case types.share:
            return  {...state,pending:false};
        case types.shareError:
            return  {...state,pending:false,error:{...action.payload}};
        case types.sharePending:
            return  {...state,pending:true};
        default:
            return state;
    }
}
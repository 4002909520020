import { types } from "../enum/types";

export const authSuccess = (auth_token,user,permisos) => ({
    type: types.authSuccess,
    payload: {
            auth_token,
            isAuthenticated:true,
            user:{...user},
            permisos:{...permisos}
    }
});

export const authClear = () => ({type: types.authClear});

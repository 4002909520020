import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';
import {routes} from './../enum/routes';
import useIsAuth from '../hooks/useIsAuth';


export const PrivateRoute = ({
    authItem = '',
    component: Component,
    layout: Layout,
    ...rest
}) => {

    const {isAuth} = useIsAuth();

    return (
        ( isAuth(authItem) ) ?
        <Layout>
            <Route {...rest} component={Component}/>
        </Layout>:
        <Redirect to={routes.login} />
    )
}

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    layout:PropTypes.func.isRequired
}

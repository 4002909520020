import React, { useState } from 'react'
import VideoCameraIcon from '../../shared/icons/VideoCameraIcon';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import ShareInfo from '../../shared/shareinfo/ShareInfo';
import {callManualAction} from './../../../actions/callManualActions';
import { useHistory } from 'react-router-dom';
import {routes} from './../../../enum/routes';

const CallManual = () => {

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const dispatch = useDispatch();
    const callManual = useSelector(state => state.callManual);

    const {pending,data} = callManual;
    const {token,urlentrada,textocompartir} = data;

    const handleSubmit = (e)=>{
        e.preventDefault();
        if(validator.isEmpty(name)){
            setNameError("Ingrese el nombre del paciente");
            return;
        }

        const data = {
            nombre: name,
            email: null,
            fechanacimiento: null,
            tel : null,
            refventa: null,
            isopais : null,
            documento : null
        }

        dispatch(callManualAction(data));

        setNameError("");

        
    }

    return (
        <div className="flex flex-col items-center justify-center">

            <div>
                <p className="italic text-gray-600 mb-4">Cree una consulta manualmente para iniciar una video llamada con un paciente no registrado.</p>
            </div>
            <div className="flex flex-col justify-center bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-8 w-full lg:w-3/4">
                <form className="flex flex-row flex-1"  onSubmit={handleSubmit}>
                    <div className="flex flex-col flex-1 relative">
                        <input  className={`flex flex-1 appearance-none border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-1
                                            ${nameError!==''?'border-red-300 text-red-700':''}`} 
                                id="name" 
                                type="text" 
                                placeholder="Nombre del Paciente"
                                maxLength="50"
                                value={name}
                                onChange={({target})=>setName(target.value)}
                        />
                        {nameError !=='' && 
                            <div className="relative">
                                <p className="text-red-500 text-xs italic absolute">{nameError}</p>
                            </div>
                        }
                    </div>
                    <button className={`flex justify-center items-center bg-vd-normal hover:bg-vd-dark text-gray-200 p-2 rounded focus:outline-none focus:shadow-outline
                                                ${pending?'opacity-50 cursor-not-allowed':''}`} 
                                    type="submit"
                                    disabled={pending}>
                            {pending?'Aguarde...':'Crear'}
                            <VideoCameraIcon className="w-6 h-6 ml-1"/>                 
                    </button>

                    
                </form>


                {token && 
                <>
                    <div className="flex flex-col mt-5">
                        <ShareInfo/>
                    </div>
                    
                    <div className="flex flex-col mt-5 items-center justify-center">
                        <p className="text-vd-dark italic">Comparta el link con el paciente, y aguarde a que aparezca en la <b>Cola de Pacientes</b>.</p>
                    </div>
                        
                </>
                }



            </div>

            
        </div>
    )
}

export default CallManual

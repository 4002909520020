import { types } from '../enum/types';
import messageSeverity from '../enum/messageSeverity';
import {useAxios} from "../hooks/useAxios";
import { manageError } from './manageErrorActions';
import { showMessage} from './messageActions';

const {sendPost} = useAxios();

export const recoverPassword = (email)=>{
    return async(dispatch)=>{

        dispatch(recoverPasswordPending());
        const result = await sendPost(`/public/OlvideContrasena?email=${email}`,{});

        if(result.hasError){
            dispatch(recoverPasswordError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(recoverPasswordSuccess());
        dispatch(showMessage('Se te envió un email',messageSeverity.success));
    };
}

export const changePasswordAction=(data)=>{

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(changePasswordPending());
        const result = await sendPost(`/user/changepass`,data,auth_token);

        if(result.hasError){
            dispatch(changePasswordError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(changePasswordSuccess(result.data.user));
        dispatch(showMessage('Se modificó la contraseña',messageSeverity.success));
    };
};

export const changeProfileDataAction=(data)=>{

    console.log(data);

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(changeProfileDataPending());
        const result = await sendPost(`/user/update`,data,auth_token);

        if(result.hasError){
            dispatch(changeProfileDataError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(changeProfileDataSuccess(result.data.user));
        dispatch(showMessage('Se modificaron los datos de tu perfil',messageSeverity.success));
    };
};


const changePasswordSuccess =(data)=>({type:types.changePasswordSuccess});
const changePasswordPending =()=>({type:types.changePasswordPending});
const changePasswordError =(code,message)=>({type:types.changePasswordError,payload:{code,message}});

const changeProfileDataSuccess =(data)=>({type:types.changeProfileDataSuccess});
const changeProfileDataPending =()=>({type:types.changeProfileDataPending});
const changeProfileDataError =(code,message)=>({type:types.changeProfileDataError,payload:{code,message}});


const recoverPasswordSuccess =()=>({type:types.recoverPasswordSuccess});
const recoverPasswordPending =()=>({type:types.recoverPasswordPending});
const recoverPasswordError =(code,message)=>({type:types.recoverPasswordError,payload:{code,message}});

import React from 'react'
import { useSelector } from 'react-redux';
import MeetNote from './MeetNote';
import MeetPacientDetail from './MeetPacientDetail';
import MeetQuestions from './MeetQuestions';

const  MeetDetail = ({token}) => {

    const {data} = useSelector(state => state.meet);

    const {pacientedetalle}=data;
  
    return (
        <div className="flex flex-col overflow-y-auto w-72">
          <div className="px-2">
             <MeetPacientDetail pacientedetalle={pacientedetalle}/>
          </div>
          <div className="p-2">
            <MeetQuestions token={token}/>
          </div>
          <div className="p-2">
            <MeetNote token={token}/>
          </div>
        </div>
    )
}

export default MeetDetail;

import { types } from '../enum/types';
import {useAxios} from "../hooks/useAxios";
import { manageError } from './manageErrorActions';

const {sendPost} = useAxios();

export const signupAction=(data)=>{

    return async(dispatch)=>{

        dispatch(signupPending());
        const result = await sendPost(`/public/registro`,data);

        if(result.hasError){
            dispatch(signupError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(signupSuccess());
    };
};

const signupSuccess =()=>({type:types.signup});
const signupPending =()=>({type:types.signupPending});
const signupError =(code,message)=>({type:types.signupError,payload:{code,message}});

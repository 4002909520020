import React from 'react'
import { useHistory, useParams } from 'react-router-dom';
import {routes} from '../../../enum/routes'

const MeetPatientEnd = () => {

    const history = useHistory();
    const {token} = useParams()

    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div className="bg-white rounded p-8">
                <p className="font-bold lg:text-2xl mb-3">
                    Gracias por usar <span className="text-vd-normal">Virtual Doctor</span>
                </p>
                <p className="font-bold text-gray-600 text-center">Su consulta ha finalizado!</p>
            </div>

            <div className="flex flex-col px-2 pt-12">
                <p className="text-gray-800 mb-2">¿Tu consulta todavía no ha finalizado?</p>
                <button className="bg-vd-normal hover:bg-vd-dark text-gray-200 py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        onClick={()=>{history.push(routes.paciente_llamadas_sala.replace(':token',token))}}
                        >Volver a la sala
                </button>
            </div>

            <div className="flex flex-col mt-12">
                <p className="text-gray-600 text-xs">
                    ¿Tienes algún problema? Comunicate con nosotros <a className="font-bold hover:text-vd-dark" href="tel:+34000000000">+34000000000</a>
                </p>
            </div>


        </div>
    )
}

export default MeetPatientEnd;

export const types = {


    userToProcessListPending: '[USER TO PROCESS] User To Process List Pending',
    userToProcessListError: '[USER TO PROCESS]  User To Process List Error',
    userToProcessListSuccess: '[USER TO PROCESS] User To Process List Success',

    userToProcessDiscardPending: '[USER TO PROCESS] User To Process Discard Pending',
    userToProcessDiscardError: '[USER TO PROCESS]  User To Process Discard Error',
    userToProcessDiscardSuccess: '[USER TO PROCESS] User To Process Discard Success',

    userToProcessApprovePending: '[USER TO PROCESS] User To Process Approve Pending',
    userToProcessApproveError: '[USER TO PROCESS]  User To Process Approve Error',
    userToProcessApproveSuccess: '[USER TO PROCESS] User To Process Approve Success',

    authLoginSuccess : '[AUTH] Login',
    authLoginPending : '[AUTH] Login Pending',
    authLoginError : '[AUTH] Login Error',
    authLogout: '[AUTH] Logout',
    authSuccess: '[AUTH] auth Success',
    authClear: '[AUTH] auth Clear',

    uiShowMessage: '[UI] Show Message',
    uiHideMessage: '[UI] Hide Message',
    
    addError: '[ERROR] Add Error',
    manageError: '[ERROR] Manage Error',

    homePending: '[USER] Home Pending',
    homeError: '[USER]  Home Error',
    homeSuccess: '[USER] Home Success',

    changePasswordPending: '[USER] Change Password Pending',
    changePasswordError: '[USER]  Change Password Error',
    changePasswordSuccess: '[USER] Change Password Success',

    changeProfileDataPending: '[USER] Change Profile Data Pending',
    changeProfileDataError: '[USER]  Change Profile Data Error',
    changeProfileDataSuccess: '[USER] Change Profile Data Success',

    recoverPasswordPending: '[USER] Recover Password Pending',
    recoverPasswordError: '[USER]  Recover Password Error',
    recoverPasswordSuccess: '[USER] Recover Password Success',

    signup: '[SIGNUP] SignUp',
    signupError: '[SIGNUP] SignUp Error',
    signupPending: '[SIGNUP] SignUp Pending',

    initMeetPending: '[MEET] Init Meet Pending',
    initMeetError: '[MEET]  Init Meet Error',
    initMeetSuccess: '[MEET] Init Meet Success',

    createMeetPending: '[MEET] Create Meet Pending',
    createMeetError: '[MEET]  Create Meet Error',
    createMeetSuccess: '[MEET] Create Meet Success',

    getMeetPending: '[MEET] Get Meet Pending',
    getMeetError: '[MEET]  Get Meet Error',
    getMeetSuccess: '[MEET] Get Meet Success',

    getNotePending: '[NOTE] Get Note Pending',
    getNoteError: '[NOTE]  Get Note Error',
    getNoteSuccess: '[NOTE] Get Note Success',

    saveNotePending: '[NOTE] Save Note Pending',
    saveNoteError: '[NOTE]  Save Note Error',
    saveNoteSuccess: '[NOTE] Save Note Success',

    endMeet:'[MEET] End Meet',
    
    callToOnHold : '[CALL] Call to On Hold',
    callRemoveToInProgress : '[CALL] Call Remove to In Progress',
    callRemoveToOnHold: '[CALL] Call Remove to On Hold',

    callStackList: '[CALL] Call Stack List',
    callStackListError: '[CALL] Call Stack List Error',
    callStackListPending: '[CALL] Call Stack List Pending',

    callOnHoldList: '[CALL] Call On Hold',
    callOnHoldListError: '[CALL] Call On Hold Error',
    callOnHoldListPending: '[CALL] Call On Hold Pending',

    callHistoryList: '[CALL] Call History',
    callHistoryError: '[CALL] Call History Error',
    callHistoryPending: '[CALL] Call History Pending',

    callManualList: '[CALL] Call Manual',
    callManualError: '[CALL] Call Manual Error',
    callManualPending: '[CALL] Call Manual Pending',

    share: '[SHARE] Share',
    shareError: '[SHARE] Share Error',
    sharePending: '[SHARE] Share Pending',

    callHistoryNextList: '[CALL] Call History Next',
    callHistoryNextError: '[CALL] Call History Next Error',
    callHistoryNextPending: '[CALL] Call History Next Pending',

    callInProgressList: '[CALL] Call In Progress',
    callInProgressListError: '[CALL] Call In Progress Error',
    callInProgressListPending: '[CALL] Call In Progress Pending',

    getQuestionSuccess: '[QUESTION] Question Get',
    getQuestionError: '[QUESTION] Question Get Error',
    getQuestionPending: '[QUESTION] Question Get Pending',

}

import messageSeverity from '../enum/messageSeverity';
import { types } from '../enum/types';
import {useAxios} from "../hooks/useAxios";
import { manageError } from './manageErrorActions';
import { showMessage } from './messageActions';

const {sendPost,sendGet} = useAxios();

export const userToProcessGetListAction=()=>{

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(getListPending());
        const result = await sendGet(`/registro/getlista`,{},auth_token);

        if(result.hasError){
            dispatch(getListError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(getListSuccess(result.data));
    };
};

export const userToProcessDiscardAction=(id)=>{

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(discardPending());
        const result = await sendPost(`/registro/marcarprocesado?idRegistro=${id}`,{},auth_token);

        if(result.hasError){
            dispatch(discardError(result.errorCode,result.errorMessage));
            dispatch(manageError(result));
            return;
        }
        dispatch(discardSuccess());
        dispatch(userToProcessGetListAction());
        dispatch(showMessage('Se ha descartado el usuario', messageSeverity.success));
    };
};

export const userToProcessApproveAction=(id)=>{

    console.log(id);

    return async(dispatch,getState)=>{

        const {auth_token} = getState().auth;

        dispatch(approvePending());
        const resultBuild = await sendPost(`/registro/buildconsultorio?idRegistro=${id}`,{},auth_token);

        if(resultBuild.hasError){
            dispatch(approveError(resultBuild.errorCode,resultBuild.errorMessage));
            dispatch(manageError(resultBuild));
            return;
        }

        console.log(resultBuild.data);

        const data = {...resultBuild.data, 
                    password: `${Math.random().toString(36).substr(2, 8)}`
        }; 
        
        console.log(data);
        

        const resultCreate = await sendPost(`/registro/crearconsultorio`,data,auth_token);

        if(resultCreate.hasError){
            dispatch(approveError(resultCreate.errorCode,resultCreate.errorMessage));
            dispatch(manageError(resultCreate));
            return;
        }
        dispatch(approveSuccess());
        dispatch(userToProcessGetListAction());
        dispatch(showMessage('Se ha aprobado el usuario', messageSeverity.success));
    };
};

const getListSuccess =(data)=>({type:types.userToProcessListSuccess, payload:data});
const getListPending =()=>({type:types.userToProcessListPending});
const getListError =(code,message)=>({type:types.userToProcessListError,payload:{code,message}});

const discardSuccess =()=>({type:types.userToProcessDiscardSuccess});
const discardPending =()=>({type:types.userToProcessDiscardPending});
const discardError =(code,message)=>({type:types.userToProcessDiscardError,payload:{code,message}});

const approveSuccess =()=>({type:types.userToProcessApproveSuccess});
const approvePending =()=>({type:types.userToProcessApprovePending});
const approveError =(code,message)=>({type:types.userToProcessApproveError,payload:{code,message}});